import React from 'react';

interface DayCardProps {
    day: number;
    borderColor: string;
    fontColor: string;
    triangleColor?: string;
    showTriangle?: boolean;
    children: React.ReactNode;
    className?: string;
}

export const DayCard = ({
    day,
    borderColor,
    fontColor,
    triangleColor,
    showTriangle = false,
    children,
    className = ''
}: DayCardProps) => {
    return (
        <div className={`relative ${className}`}>
            {/* Main card container */}
            <div
                className="rounded-lg overflow-hidden"
                style={{ border: `1px solid ${borderColor}` }}
            >
                {/* Header */}
                <div
                    className="py-2 px-4 text-center"
                    style={{ backgroundColor: borderColor }}
                >
                    <span
                        className="text-sm font-medium"
                        style={{ color: fontColor }}
                    >
                        Day {day}
                    </span>
                </div>
                {/* Content */}
                <div className="p-4">
                    {children}
                </div>
            </div>
            {/* Triangle - 45度に変更 */}
            {showTriangle && (
                <div
                    className="absolute -right-[18px] top-1/2 -translate-y-1/2 w-9 h-9"
                    style={{
                        transform: 'translateY(-50%)',
                    }}
                >
                    <div
                        className="w-full h-full"
                        style={{
                            backgroundColor: triangleColor,
                            clipPath: 'polygon(50% 0, 100% 50%, 50% 100%)'
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default DayCard;