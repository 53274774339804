import React from 'react';
import { ExternalLink } from 'lucide-react';

interface ExternalLinkButtonProps {
    href: string;
    title: string;
    description?: string;
}

const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = ({ href, title, description }) => {
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="group block w-full bg-[#F7F8F8]"
        >
            <div className="border rounded-lg p-4 hover:border-[#F9825D] transition-colors duration-200 shadow-sm">
                <div className="flex items-center justify-between">
                    <div>
                        <h3 className="text-lg font-medium text-gray-900 group-hover:text-[#F9825D]">
                            {title}
                        </h3>
                        {description && (
                            <p className="mt-1 text-sm text-gray-500">
                                {description}
                            </p>
                        )}
                    </div>
                    <ExternalLink className="w-5 h-5 text-gray-400 group-hover:text-[#F9825D]" />
                </div>
            </div>
        </a>
    );
};

const LegalLinks: React.FC = () => {
    return (
        <div className="space-y-4 bg-[#F7F8F8]">
            <ExternalLinkButton
                href="https://telegram.org/tos"
                title="Terms of Service"
                description="Read Telegram's terms of service"
            />
            <ExternalLinkButton
                href="https://telegram.org/privacy?setln=fa"
                title="Privacy Policy"
                description="View Telegram's privacy policy"
            />
        </div>
    );
};

export default LegalLinks;