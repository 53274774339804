import { useAtom } from 'jotai';
import { useState } from 'react';
import { masterDataAtom, taskProgressAtom, coinsAtom, claimLeagueTaskAtom } from '../../../atoms';
import { TaskTabs } from '../../../../components/tasks/tasktabs';
import SingleTaskCard from '../../../../components/tasks/SingleTaskCard';
import { levelsAtom } from '../../../atoms';

export const LeaguesTaskRoute = () => {
    const [masterData] = useAtom(masterDataAtom);
    const [taskProgress, setTaskProgress] = useAtom(taskProgressAtom);
    const [coins] = useAtom(coinsAtom);
    const [, claimLeagueTask] = useAtom(claimLeagueTaskAtom);
    const [claimedTaskIds, setClaimedTaskIds] = useState<Set<string>>(new Set());
    const [levels] = useAtom(levelsAtom);

    const calculateTotalCoins = () => {
        return {
            clickEarnedCoins: coins.clickEarnedCoins || 0,
            tapbotEarnedCoins: coins.calculation.earnedCoins.botTotalEarned || 0,
            total: (coins.clickEarnedCoins || 0) + (coins.calculation.earnedCoins.botTotalEarned || 0)
        };
    };

    const canClaimTask = (taskId: string, league: { requiredClickEarnedCoins: number } | undefined) => {
        const progress = taskProgress.leagueTasks.find(p => p.taskId === taskId);
        const earnedCoins = calculateTotalCoins();
        const isLeagueCompleted = league && earnedCoins.total >= league.requiredClickEarnedCoins;
        const isClaimedLocally = claimedTaskIds.has(taskId);

        if (progress?.rewardClaimed || isClaimedLocally) {
            return false;
        }

        return progress?.completedAt || isLeagueCompleted ? true : false;
    };

    const handleClaim = async (taskId: string) => {
        try {
            await claimLeagueTask(taskId);
            setClaimedTaskIds(prev => new Set(prev).add(taskId));
            setTaskProgress(prev => ({
                ...prev,
                leagueTasks: prev.leagueTasks.map(task =>
                    task.taskId === taskId
                        ? { ...task, rewardClaimed: true, completedAt: Date.now() }
                        : task
                )
            }));
        } catch (error) {
            console.error('Failed to claim task:', error);
        }
    };

    const getTaskRewards = (task: typeof masterData.leagueTasks[0], progress: typeof taskProgress.leagueTasks[0] | undefined) => {
        if (progress?.rewardClaimed) {
            // 報酬受け取り済みの場合は、その報酬額のみを含む配列を返す
            return [{
                level: 0,
                reward: progress.rewardCoins
            }];
        }
        // 未受領の場合は、通常の報酬配列を返す
        return task.rewards;
    };

    const renderTasks = () => {
        const earnedCoins = calculateTotalCoins();

        return (
            <div className="mx-4 mb-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {masterData.leagueTasks.map(task => {
                        const league = masterData.leagues.find(l => l.leagueId === task.leagueId);
                        const progress = taskProgress.leagueTasks.find(p => p.taskId === task.taskId);
                        const isClaimedLocally = claimedTaskIds.has(task.taskId);

                        return (
                            <SingleTaskCard
                                key={task.taskId}
                                task={{
                                    taskId: task.taskId,
                                    title: task.title,
                                    reward: getTaskRewards(task, progress),
                                    requiredCoins: league?.requiredClickEarnedCoins || 0
                                }}
                                currentCoins={earnedCoins.total}
                                status=""
                                canClaim={canClaimTask(task.taskId, league)}
                                completedAt={progress?.completedAt}
                                rewardClaimed={progress?.rewardClaimed || isClaimedLocally}
                                taskIconUrl={league?.badgeLargeUrl}
                                currentTaskLevel={progress?.rewardClaimed ? 0 : levels.leagueTaskLevel}
                                onClaim={handleClaim}
                            />
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div
            className="max-w-7xl mx-auto overflow-scroll h-screen bg-[#F7F8F8]"
            style={{
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 8rem)',
            }}
        >
            <TaskTabs />
            {renderTasks()}
        </div>
    );
};

export default LeaguesTaskRoute;