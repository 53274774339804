import React from 'react';
import { useNavigate } from 'react-router-dom';
import { OutlineButton } from '../../ui/outline-button/OutlineButton';
import { paths } from '../../../config/paths';
import boostIcon from '../../../assets/fujiyamatap-icons/ui/boost.webp';
import boostForDarkStageIcon from '../../../assets/fujiyamatap-icons/ui/boost-for-dark-stage.webp';
import energyIcon from '../../../assets/fujiyamatap-icons/ui/energy.webp';

interface EnergyControlsProps {
    currentEnergy: number;
    maxEnergy: number;
    isBoostActive?: boolean;
    isDarkStage?: boolean;
    className?: string;
}

export const EnergyControls: React.FC<EnergyControlsProps> = ({
    currentEnergy,
    maxEnergy,
    isBoostActive = false,
    isDarkStage = false,
    className = ""
}) => {
    const navigate = useNavigate();
    const textColorClass = isDarkStage ? 'text-white' : 'text-black';

    return (
        <div className={`pr-4 pl-8 ${className}`}>
            <div className="flex justify-between items-center">
                <div className="flex items-end gap-2">
                    <img src={energyIcon} alt="Energy" className="h-8" />
                    <span className={`text-sm font-mplus1p font-light ${textColorClass}`}>
                        {Math.floor(currentEnergy)}/{maxEnergy}
                        {isBoostActive && <span className="ml-1 text-green-600">∞</span>}
                    </span>
                </div>
                <OutlineButton
                    icon={<img src={isDarkStage ? boostForDarkStageIcon : boostIcon} alt="Boost" className="h-5" />}
                    onClick={() => navigate(paths.home.boosts.path)}
                    variant={isDarkStage ? "light" : "dark"}
                >
                    Boost
                </OutlineButton>
            </div>
        </div>
    );
};