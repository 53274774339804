import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    className?: string;
    position?: 'center' | 'bottom';
}

const Modal = ({
    isOpen,
    onClose,
    children,
    className = '',
    position = 'center'
}: ModalProps) => {
    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape' && isOpen) {
                onClose();
            }
        };

        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
            document.body.style.overflow = '';
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    const handleBackdropClick = (e: React.MouseEvent) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const positionClass = position === 'bottom'
        ? 'items-end'
        : 'items-center';

    // TonConnectのモーダルがz-index: 1000なので
    // それより低い値を設定し、かつ他のUIより上に表示されるよう
    // 十分に大きな値（例：900）を設定
    const modalContent = (
        <div
            className={`fixed inset-0 z-[900] flex justify-center bg-black/50 ${positionClass}`}
            onClick={handleBackdropClick}
            style={{
                paddingTop: 'env(safe-area-inset-top)',
                paddingBottom: 'env(safe-area-inset-bottom)',
                paddingLeft: 'env(safe-area-inset-left)',
                paddingRight: 'env(safe-area-inset-right)'
            }}
        >
            <div
                className={`bg-white shadow-xl w-full relative transform transition-all 
                    ${position === 'bottom' ? 'rounded-t-2xl' : 'rounded-xl mx-4'} 
                    ${className}`}
                role="dialog"
                aria-modal="true"
                onClick={e => e.stopPropagation()}
            >
                {children}
            </div>
        </div>
    );

    const modalRoot = document.getElementById('modal-root') || document.body;
    return createPortal(modalContent, modalRoot);
};

export default Modal;