import { useTonConnectModal, useTonWallet } from '@tonconnect/ui-react';
import { DailyCheckinPackage } from '../../../../../functions/api/v1/users/challenge/type';
import Modal from '../Modal';
import { X } from 'lucide-react';
import DayCard from './DayCard';

import tonIcon from '../../../../assets/fujiyamatap-icons/ui/ton_symbol.svg'
import coinIcon from '../../../../assets/fujiyamatap-icons/ui/coin.webp'
import sakuraBotIcon from '../../../../assets/fujiyamatap-icons/boost/sakura-bot.webp'

const MAX_LOGIN_DAYS = 30;

interface DailyCheckinModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (itemId: string, priceId: string, currencyId: string) => void;
    packages: DailyCheckinPackage[];
    isPerformingCheckin: boolean;
    processingPaymentId?: string | null;
}

const DailyCheckinModal = ({
    isOpen,
    onClose,
    onSelect,
    packages,
    isPerformingCheckin,
    processingPaymentId
}: DailyCheckinModalProps) => {
    const { open } = useTonConnectModal();
    const wallet = useTonWallet();

    const handleSelect = (pkg: DailyCheckinPackage) => {
        if (pkg.price?.currency.currencyId === 'ton' && !wallet) {
            open();
            return;
        }
        onSelect(
            pkg.itemId,
            pkg.price?.priceId || '',
            pkg.price?.currency.currencyId || 'coin'
        );
    };

    const getCurrencyIcon = (currencyId: string) => {
        switch (currencyId) {
            case 'ton':
                return tonIcon;
            case 'coin':
                return coinIcon;
            default:
                return coinIcon;
        }
    };

    const isProcessingPackage = (pkg: DailyCheckinPackage) => {
        return !!(isPerformingCheckin || (processingPaymentId && pkg.price?.priceId === processingPaymentId));
    };

    const getButtonContent = (pkg: DailyCheckinPackage) => {
        if (isProcessingPackage(pkg)) {
            return (
                <div className="flex items-center justify-center space-x-2 font-mplus1p font-light">
                    <svg className="animate-spin h-4 w-4 mr-2" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    <span>Processing...</span>
                </div>
            );
        }

        return pkg.price ? (
            <div className="flex items-center justify-center space-x-2 font-mplus1p ">
                <img
                    src={getCurrencyIcon(pkg.price.currency.currencyId)}
                    alt={pkg.price.currency.currencyId}
                    className="w-4 h-4"
                />
                <span className="font-light">{pkg.price.price} {pkg.price.currency.symbol}</span>
                <span className="font-thin">check-in</span>
            </div>
        ) : 'Free check-in';
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            position="bottom"
            className="h-[80vh] rounded-t-2xl transform transition-transform duration-300 ease-out"
        >
            <div className="h-full flex flex-col relative font-mplus1p overflow-y-scroll">
                <div className="absolute top-0 left-0 right-0 flex justify-center gap-4 items-center px-2 pt-4">
                    <img src={sakuraBotIcon} alt="Sakura Bot" className="w-8 h-8" />
                    <h1 className="font-mplus1p font-light text-2xl text-[#1A1A1A]">Daily Bonus</h1>
                    <img src={sakuraBotIcon} alt="Sakura Bot" className="w-8 h-8" />
                </div>

                <button
                    onClick={onClose}
                    disabled={isPerformingCheckin}
                    className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full transition-colors duration-200 disabled:opacity-50"
                >
                    <X className="w-6 h-6 text-gray-500" />
                </button>

                <div className="flex-1 px-6 pt-16 pb-2">
                    <div className="space-y-4">
                        {[...packages].reverse().map((pkg) => (
                            <div
                                key={pkg.itemId}
                                className="border rounded-lg p-4 bg-white hover:bg-gray-50 transition-colors"
                            >
                                <div className="flex gap-4 mb-2 justify-center">
                                    {pkg.rewardToday.days === MAX_LOGIN_DAYS ? (
                                        <>
                                            <DayCard
                                                day={pkg.rewardToday.days - 1}
                                                borderColor="#E5E7EB"
                                                fontColor="#6B7280"
                                                triangleColor="#E5E7EB"
                                                showTriangle={true}
                                                className="flex-1"
                                            >
                                                <div className="text-center">
                                                    <span className="text-lg font-thin text-gray-400">
                                                        ???
                                                    </span>
                                                    <span className="text-sm font-light text-gray-400">&nbsp;coins</span>
                                                </div>
                                            </DayCard>

                                            <DayCard
                                                day={pkg.rewardToday.days}
                                                borderColor="#008000"
                                                fontColor="#FFFFFF"
                                                triangleColor="#008000"
                                                showTriangle={false}
                                                className="flex-1"
                                            >
                                                <div className="text-center">
                                                    <span className="text-lg font-light text-[#008000]">
                                                        {pkg.rewardToday.rewardCoin.toLocaleString()}
                                                    </span>
                                                    <span className="text-sm font-light text-gray-600">coins</span>
                                                </div>
                                            </DayCard>
                                        </>
                                    ) : (
                                        <>
                                            <DayCard
                                                day={pkg.rewardToday.days}
                                                borderColor="#DE6437"
                                                fontColor="#FFFFFF"
                                                triangleColor="#DE6437"
                                                showTriangle={true}
                                                className="flex-1"
                                            >
                                                <div className="text-center">
                                                    <span className="text-lg font-light text-[#DE6437]">
                                                        {pkg.rewardToday.rewardCoin.toLocaleString()}
                                                    </span>
                                                    <span className="text-sm font-light text-gray-600">coins</span>
                                                </div>
                                            </DayCard>

                                            <DayCard
                                                day={pkg.rewardToday.days + 1}
                                                borderColor="#E5E7EB"
                                                fontColor="#6B7280"
                                                className="flex-1"
                                            >
                                                <div className="text-center">
                                                    <span className="text-lg font-thin text-gray-400">
                                                        ???
                                                    </span>
                                                    <span className="text-sm font-light text-gray-400">coins</span>
                                                </div>
                                            </DayCard>
                                        </>
                                    )}
                                </div>

                                <div className="mb-2">
                                    <span className="font-light text-lg text-[#1A1A1A]">{pkg.name}</span>
                                    <div className="text-sm font-light text-gray-600 mt-1">{pkg.description}</div>
                                </div>

                                <button
                                    onClick={() => handleSelect(pkg)}
                                    disabled={isProcessingPackage(pkg)}
                                    className={`
                                        w-full py-2 rounded-lg font-light
                                        ${pkg.price?.currency.currencyId === 'coin'
                                            ? 'bg-white border border-[#DE6437] text-[#1A1A1A] hover:bg-gray-50'
                                            : 'bg-[#DE6437] hover:bg-[#C55832] text-white'
                                        }
                                        transform hover:scale-105
                                        transition-all duration-200
                                        focus:outline-none focus:ring-2 focus:ring-[#DE6437] focus:ring-offset-2
                                        ${isProcessingPackage(pkg) ? 'opacity-50 cursor-not-allowed' : ''}
                                    `}
                                >
                                    {getButtonContent(pkg)}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                <div style={{ paddingBottom: 'env(safe-area-inset-bottom)' }} />
            </div>
        </Modal>
    );
};

export default DailyCheckinModal;