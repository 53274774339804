// hooks/useEarningsPopup.ts
import { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { tapBotEarningsModalFlagAtom } from '../../../../app/atoms';

interface UseEarningsPopupProps {
    earnings: number;
    isInitLoading: boolean;
}

export const useEarningsPopup = ({ earnings, isInitLoading }: UseEarningsPopupProps) => {
    const [showEarningsModal, setShowEarningsModal] = useState(false);
    const [isModalEnabled, setIsModalEnabled] = useAtom(tapBotEarningsModalFlagAtom);

    useEffect(() => {
        // 初期ローディング完了時、収益が存在し、モーダル表示が有効な場合のみ表示
        if (!isInitLoading && earnings > 0 && isModalEnabled) {
            setShowEarningsModal(true);
        }
    }, [isInitLoading, earnings, isModalEnabled, setIsModalEnabled]);

    const handleCloseModal = () => {
        setShowEarningsModal(false);
    };

    return {
        showEarningsModal,
        handleCloseModal,
    };
};

export default useEarningsPopup;