import { useState, useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';
import { useTonConnectModal, useTonWallet, useTonConnectUI } from '@tonconnect/ui-react';
import { toNano, beginCell } from '@ton/ton';
import { useNavigate } from 'react-router-dom';
import { backButton } from '@telegram-apps/sdk-react';

import {
    masterDataAtom,
    levelsAtom,
    coinsAtom,
    purchaseActionAtom,
    clickerAtom,
    itemHistoriesAtom,
    tapBotStateAtom,
    localClickerStateAtom,
    currentLeagueAtom
} from '../../atoms';

import { useTonPayment } from '../../../components/payment/ton/usetonpayment';
import { useToast } from '../../../components/toast/useToast';
import { useLocation } from 'react-router-dom';
import { usePaymentRecovery } from '../../../components/payment/ton/usePaymentRecovery';
import PaymentRecoveryModal from '../../../components/payment/ton/PaymentRecovery';
import { PaymentItemType } from '../../../../functions/api/v1/payment/ton/transactions/type';


import GameHeader from '../../../components/GameHeader/GameHeader';
import ItemFilledButton from '../../../components/clicker/items/ItemFilledButton/ItemFilledButton';
import ItemOutlineButton from '../../../components/clicker/items/ItemOutlineButton/ItemOutlineButton';

import boostIcon from '../../../assets/fujiyamatap-icons/ui/boost.webp';
import energyIcon from '../../../assets/fujiyamatap-icons/ui/energy.webp';
import rechargeSpeedIcon from '../../../assets/fujiyamatap-icons/boost/recharging-speed.webp';
import tapPowerIcon from '../../../assets/fujiyamatap-icons/boost/multitap.webp';
import energyLimitIcon from '../../../assets/fujiyamatap-icons/boost/energy-limit.webp';
import { TapBotLevelMaster } from '../../../../functions/api/v1/users/challenge/type';
import { PurchaseItemType } from '../../../../functions/api/v1/users/purchase/clicker_items/type';

export const ItemsRoute = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {
        pendingPayments,
        showRecoveryModal,
        removePendingPayment,
        clearAllPendingPayments
    } = usePaymentRecovery();

    const { showToast } = useToast();
    useEffect(() => {
        backButton.show();
        backButton.onClick(() => {
            navigate(-1);
            backButton.hide();
        });
        return () => {
            backButton.hide();
        };
    }, [navigate]);
    const [processingPaymentId, setProcessingPaymentId] = useState<string | null>(null);
    const { addPendingPayment } = usePaymentRecovery();

    const [masterData] = useAtom(masterDataAtom);
    const [levels] = useAtom(levelsAtom);
    const [coins] = useAtom(coinsAtom);
    const [clicker] = useAtom(clickerAtom);
    const [itemHistories] = useAtom(itemHistoriesAtom);
    const [tapBotState] = useAtom(tapBotStateAtom);
    const [, purchase] = useAtom(purchaseActionAtom);
    const [localState] = useAtom(localClickerStateAtom);

    const [, setAvailableCoins] = useState(0);
    const [purchasing, setPurchasing] = useState(false);

    const { open } = useTonConnectModal();
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();
    const {
        createTransaction,
        waitForTransactionComplete,
    } = useTonPayment();

    useEffect(() => {
        const resumePaymentData = location.state?.paymentData;
        if (resumePaymentData) {
            setProcessingPaymentId(resumePaymentData.transactionId);
            setPurchasing(true);
            waitForTransactionComplete({
                transactionId: resumePaymentData.transactionId,
                tonDestinationWalletAddress: resumePaymentData.tonDestinationWalletAddress,
                price: resumePaymentData.price,
                createdAt: resumePaymentData.createdAt,
                itemType: resumePaymentData.itemType
            }).then(async (verification) => {
                if (verification.verified) {
                    await purchase({
                        itemType: resumePaymentData.itemType,
                        priceId: resumePaymentData.priceId,
                        transactionId: resumePaymentData.transactionId
                    });
                    removePendingPayment(resumePaymentData.transactionId);
                    showToast(
                        'Payment Successful',
                        'success',
                        'Your payment has been successfully processed.'
                    );
                } else {
                    showToast(
                        'Payment Failed',
                        'error',
                        'Unable to verify your payment. Please try again.'
                    );
                }
            }).catch((error) => {
                console.error('Resume payment failed:', error);
                showToast(
                    'Payment Error',
                    'error',
                    'An unexpected error occurred while processing your payment.'
                );
            }).finally(() => {
                setProcessingPaymentId(null);
                setPurchasing(false);
                navigate(location.pathname, { replace: true });
            });
        }
    }, [location.state]);

    const [serverCoins] = useAtom(coinsAtom);
    const [currentLeague] = useAtom(currentLeagueAtom);

    // ユーザーの現在のレベルとパラメータを取得
    const currentTapLevel = masterData.tapLevels.find(
        level => level.level === levels.tapLevel
    );
    const currentEnergyLevel = masterData.energyLimitLevels.find(
        level => level.level === levels.energyLimitLevel
    );
    const currentRechargeLevel = masterData.rechargeSpeedLevels.find(
        level => level.level === levels.rechargeSpeedLevel
    );
    // TapBotレベルの取得ロジックを修正
    const currentTapBotLevel = levels.tapBotLevel !== undefined
        ? masterData.tapBotLevels.find(level => level.level === levels.tapBotLevel)
        : undefined;

    // マスターデータから次のレベルを取得
    const nextTapLevel = masterData.tapLevels.find(
        level => level.level === levels.tapLevel + 1
    );
    const nextEnergyLevel = masterData.energyLimitLevels.find(
        level => level.level === levels.energyLimitLevel + 1
    );
    const nextRechargeLevel = masterData.rechargeSpeedLevels.find(
        level => level.level === levels.rechargeSpeedLevel + 1
    );

    // Get free boost items and usage counts
    const quickBoostItem = masterData.multipliesBoostItems.find(item => item.itemId === 'quick-boost');
    const freeBoostPrice = quickBoostItem?.prices.find(price => price.price === 0);

    const freeFullChargeItem = masterData.fullChargeItems.find(item =>
        item.prices.some(price => price.price === 0)
    );
    const freeFullChargePrice = freeFullChargeItem?.prices.find(price => price.price === 0);

    const boostUsageCount = itemHistories.boostHistories.filter(
        history => history.price === 0
    ).length;
    const remainingBoosts = clicker.freeBoostDosageLimit - boostUsageCount;

    const fullChargeUsageCount = itemHistories.fullChargeHistories.filter(
        history => history.price === 0
    ).length;
    const remainingFullCharges = clicker.freeRechargeLimit - fullChargeUsageCount;

    // Check if user already has the specific TapBot level
    const hasTapBotLevel = (level: number) => {
        return currentTapBotLevel?.level === level;
    };

    // Modified TapBot purchase check
    const isTapBotPurchaseDisabled = (botLevel: number) => {
        const alreadyHasThisBot = hasTapBotLevel(botLevel);
        const hasHigherLevelBot = currentTapBotLevel ? currentTapBotLevel.level >= botLevel : false;
        return alreadyHasThisBot || hasHigherLevelBot;
    };

    // 購入可能判定を修正
    const canPurchaseItem = (itemType: string, price: number, currencyId: string = 'coin') => {
        // Premium TapBotを持っている場合、クリッカーアイテムは購入不可
        if (hasPremiumTapBot && ['tap_level', 'energy_limit', 'recharge_speed'].includes(itemType)) {
            return false;
        }

        // TONの場合は金額による制限はなし
        if (currencyId === 'ton') {
            return true;
        }

        // コインの場合のみ残高チェック
        return currencyId === 'coin' ? availableCoinsCalculation >= price : true;
    };

    // Modified display logic for items
    const isItemDisabled = (itemType: string, prices: { price: number, currencyId: string }[]) => {
        // If user has Premium TapBot, disable clicker items
        if (hasPremiumTapBot && ['tap_level', 'energy_limit', 'recharge_speed'].includes(itemType)) {
            return true;
        }
        // If item is at max level, disable it
        if (isMaxLevel(itemType)) {
            return true;
        }
        // 少なくとも1つの通貨で購入可能であればfalse
        return !prices.some(({ price, currencyId }) => canPurchaseItem(itemType, price, currencyId));
    };

    // 最大レベルチェックを追加
    const isMaxLevel = (itemType: string) => {
        switch (itemType) {
            case 'tap_level':
                return hasPremiumTapBot || levels.tapLevel >= maxLevels.tapLevel;
            case 'energy_limit':
                return hasPremiumTapBot || levels.energyLimitLevel >= maxLevels.energyLevel;
            case 'recharge_speed':
                return hasPremiumTapBot || levels.rechargeSpeedLevel >= maxLevels.rechargeLevel;
            default:
                return false;
        }
    };

    // 利用可能なコインの計算をuseMemoで実装
    const availableCoinsCalculation = useMemo(() => {
        if (!localState.isInitialized) {
            return coins.calculation.totalCoins;
        }

        // 支出の計算
        const spentCoins = localState.localCoinCalculation.spentCoins.totalSpent;

        // 収入の計算
        const earnedCoins = localState.localCoinCalculation.earnedCoins;
        const totalEarned = (
            earnedCoins.clickEarned +
            tapBotState.botEarnedCoins +
            earnedCoins.cinemaTaskRewards +
            earnedCoins.specialTaskRewards +
            earnedCoins.leagueTaskRewards +
            earnedCoins.referralTaskRewards +
            earnedCoins.dailyCheckinRewards
        );

        return totalEarned - spentCoins;
    }, [
        localState.isInitialized,
        localState.localCoinCalculation,
        tapBotState.botEarnedCoins,
        coins.calculation.totalCoins
    ]);

    // Premium TapBotによるレベル最大化状態のチェックを修正
    const hasPremiumTapBot = useMemo(() => {
        return currentTapBotLevel?.clickerItemsLvMax || false;
    }, [currentTapBotLevel]);

    const maxLevels = {
        tapLevel: masterData.tapLevels[masterData.tapLevels.length - 1].level,
        energyLevel: masterData.energyLimitLevels[masterData.energyLimitLevels.length - 1].level,
        rechargeLevel: masterData.rechargeSpeedLevels[masterData.rechargeSpeedLevels.length - 1].level
    };

    // 利用可能なコインの更新
    useEffect(() => {
        setAvailableCoins(availableCoinsCalculation);
    }, [availableCoinsCalculation]);

    // TapBotレベルを昇順にソート
    const sortedTapBotLevels = [...masterData.tapBotLevels].sort((a, b) => a.level - b.level);
    const highestTierBot = sortedTapBotLevels[sortedTapBotLevels.length - 1];

    // handlePurchase関数の修正
    const handlePurchase = async (
        itemType: 'energy_limit' | 'recharge_speed' | 'tap_level' | 'tapbot' | 'full_charge' | 'boost',
        priceId: string,
        price: number,
        currencyId: string = 'coin'
    ) => {
        if (purchasing) return;

        if (currencyId === 'coin') {
            if (!canPurchaseItem(itemType, price)) return;
            setPurchasing(true);
            try {
                await purchase({
                    itemType,
                    priceId
                });
                setAvailableCoins(prev => prev - price);
            } catch (error) {
                console.error('Purchase failed:', error);
            } finally {
                setPurchasing(false);
            }
            return;
        }

        // TON支払いの場合
        try {
            setPurchasing(true);

            if (!wallet) {
                open();
                return;
            }

            const transaction = await createTransaction({
                itemType,
                priceId
            });

            // https://docs.ton.org/mandarin/v3/guidelines/dapps/cookbook#what-flags-are-there-in-user-friendly-addresses:~:text=TEP%2D2%3A-,Address%20beginning,-Binary%20form
            const isWalletAddressOnTestnet = wallet.account.chain !== '-239';
            const isDestinationAddressOnTestnet = transaction.tonDestinationWalletAddress.startsWith('k') || transaction.tonDestinationWalletAddress.startsWith('0');
            if (isWalletAddressOnTestnet !== isDestinationAddressOnTestnet) {
                throw new Error('Wallet and destination address are on different networks');
            }

            // トランザクションコメントの生成
            const encoder = new TextEncoder();
            const data = encoder.encode(transaction.transactionId);
            const hashBuffer = await crypto.subtle.digest('SHA-256', data);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashedId = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

            // ウォレットでの承認待ち
            await tonConnectUI.sendTransaction({
                validUntil: Date.now() + 5 * 60 * 1000,
                messages: [
                    {
                        address: transaction.tonDestinationWalletAddress,
                        amount: toNano(transaction.price).toString(),
                        payload: beginCell()
                            .storeUint(0, 32)
                            .storeStringTail(hashedId)
                            .endCell()
                            .toBoc()
                            .toString('base64')
                    },
                ],
            });

            addPendingPayment({
                itemType: itemType as PaymentItemType,
                priceId: priceId,
                price: transaction.price,
                transactionId: transaction.transactionId,
                tonDestinationWalletAddress: transaction.tonDestinationWalletAddress,
                createdAt: transaction.createdAt,
                currencyId: 'ton'
            });

            const verification = await waitForTransactionComplete(transaction);

            if (!verification.verified) {
                throw new Error('Transaction verification failed');
            }

            await purchase({
                itemType,
                priceId,
                transactionId: transaction.transactionId
            });
            removePendingPayment(transaction.transactionId);

        } catch (error) {
            console.error('Purchase failed:', error);
        } finally {
            setPurchasing(false);
            setTimeout(() => {
            }, 3000);
        }
    };

    // 表示用の値を計算
    const displayValues = useMemo(() => {
        if (!localState.isInitialized) {
            return {
                totalCoins: serverCoins.calculation.totalCoins,
            };
        }
        return {
            totalCoins: localState.localCoinCalculation.totalCoins,
        };
    }, [
        localState.isInitialized,
        localState.localCoinCalculation.totalCoins,
        serverCoins.calculation.totalCoins,
    ]);

    const getSpecialFeatures = (tapBotItem: TapBotLevelMaster) => {
        const features: React.ReactNode[] = [];

        if (tapBotItem.clickerItemsLvMax) {
            features.push(
                <div key="clicker" className="flex items-center gap-2">
                    <span>
                        Maximizes all clicker items to Level {maxLevels.tapLevel}/{maxLevels.energyLevel}/{maxLevels.rechargeLevel}
                    </span>
                </div>
            );
        }

        if (tapBotItem.taskLvMax) {
            features.push(
                <div key="tasks" className="flex items-center gap-2">
                    <span>Maximizes all task levels</span>
                </div>
            );
        }

        return features;
    };

    return (
        <div className="grid grid-cols-1 overflow-scroll h-screen bg-[#F8F7F7]"
            style={{
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 8rem)',
            }}
        >
            {/* 追加: 支払い復旧モーダル */}
            <PaymentRecoveryModal
                isOpen={showRecoveryModal}
                pendingPayments={pendingPayments}
                processingPaymentId={processingPaymentId}
                onProcess={async (transactionId) => {
                    try {
                        const payment = pendingPayments.find(p => p.transactionId === transactionId);
                        if (!payment) {
                            showToast(
                                'Payment Error',
                                'error',
                                'Payment information not found.'
                            );
                            return false;
                        }

                        setPurchasing(true);
                        const verification = await waitForTransactionComplete({
                            transactionId: payment.transactionId,
                            tonDestinationWalletAddress: payment.tonDestinationWalletAddress,
                            price: payment.price,
                            createdAt: payment.createdAt,
                            itemType: payment.itemType
                        });

                        if (verification.verified) {
                            await purchase({
                                itemType: payment.itemType as PurchaseItemType,
                                priceId: payment.priceId,
                                transactionId: payment.transactionId
                            });
                            removePendingPayment(payment.transactionId);
                            showToast(
                                'Payment Successful',
                                'success',
                                'Your payment has been successfully processed.'
                            );
                            return true;
                        }

                        showToast(
                            'Payment Failed',
                            'error',
                            'Unable to verify your payment. Please try again.'
                        );
                        return false;

                    } catch (error) {
                        console.error('Payment verification failed:', error);
                        showToast(
                            'Payment Error',
                            'error',
                            'An unexpected error occurred while processing your payment.'
                        );
                        return false;
                    } finally {
                        setPurchasing(false);
                    }
                }}
                onCancel={clearAllPendingPayments}
            />

            {/* tonconnect モーダルの表示 */}
            <GameHeader
                currentLeague={currentLeague}
                totalCoins={displayValues.totalCoins}
            />
            <div className=' px-4'>

                <div className="grid grid-cols-1 gap-4">
                    {/* 最上位プラン（画面上部に表示） */}
                    {highestTierBot && (
                        <>
                            <h2 className="text-xl font-regular text-[#1A1A1A]">Premium TapBot</h2>
                            <ItemOutlineButton
                                isTapBot={true}
                                title={highestTierBot.name}
                                iconUrl={highestTierBot.iconUrl}
                                description={highestTierBot.description}
                                currentLevel={currentTapBotLevel?.level ?? 0}
                                nextLevel={highestTierBot.level}
                                hasPremiumTapBot={hasPremiumTapBot}
                                canPurchase={!isTapBotPurchaseDisabled(highestTierBot.level) && highestTierBot.prices.some(
                                    ({ price, currencyId }) => canPurchaseItem('tapbot', price, currencyId)
                                )}
                                purchasing={purchasing}
                                isDisabled={isTapBotPurchaseDisabled(highestTierBot.level)}
                                isMaxLevel={false}
                                isHighestTier={highestTierBot.level === highestTierBot.level}
                                duration={highestTierBot.duration}
                                stock={highestTierBot.stock}
                                remains={highestTierBot.remains}
                                specialFeatures={getSpecialFeatures(highestTierBot)}
                                prices={highestTierBot.prices}
                                onPurchase={async (priceId, price, currencyId) => {
                                    await handlePurchase('tapbot', priceId, price, currencyId);
                                }}
                            />
                        </>
                    )}

                    <div className="w-full">
                        <h2 className="text-xl font-regular text-[#1A1A1A] mb-4">Free Daily Items</h2>
                        <div className="flex items-stretch justify-center gap-2 h-auto">
                            {/*Free Boost*/}
                            <ItemFilledButton
                                icon={boostIcon}
                                title={quickBoostItem!.name}
                                description={quickBoostItem!.description}
                                remainingUses={remainingBoosts}
                                maxUses={clicker.freeBoostDosageLimit}
                                isDisabled={remainingBoosts <= 0 || purchasing}
                                onUse={async () => {
                                    await handlePurchase('boost', freeBoostPrice!.priceId, 0);
                                    navigate('/');
                                }}
                                confirmText="Activate Boost"
                                onSuccess={() => {
                                    showToast("Boost Activated", "success", "Boost activated! Your tap power has been increased.",);
                                }}
                                navigateAfterPurchase={true}
                            />


                            {/*Free Full Charge*/}
                            <ItemFilledButton
                                icon={energyIcon}
                                title={freeFullChargeItem!.name}
                                description={freeFullChargeItem!.description}
                                remainingUses={remainingFullCharges}
                                maxUses={clicker.freeRechargeLimit}
                                isDisabled={remainingFullCharges <= 0 || purchasing}
                                onUse={async () => {
                                    await handlePurchase('full_charge', freeFullChargePrice!.priceId, 0);
                                    navigate('/');
                                }}
                                confirmText="Restore Energy"
                                onSuccess={() => {
                                    showToast("Energy Restored", "success", "Energy restored! You can continue tapping.");
                                }}
                            />
                        </div>
                    </div>

                    <h2 className="text-xl font-regular text-[#1A1A1A]">Level Up Items</h2>

                    {/* Tap Power */}
                    <ItemOutlineButton
                        title="Tap Power"
                        iconUrl={tapPowerIcon}
                        description="Increase amount of TAP you can earn per one tap. +1 per tap for each level."
                        currentLevel={currentTapLevel?.level ?? 0}
                        nextLevel={nextTapLevel?.level}
                        hasPremiumTapBot={hasPremiumTapBot}
                        canPurchase={nextTapLevel! && nextTapLevel.prices.some(
                            ({ price, currencyId }) => canPurchaseItem('tap_level', price, currencyId)
                        )}
                        purchasing={purchasing}
                        isMaxLevel={isMaxLevel('tap_level')}
                        prices={nextTapLevel?.prices ?? []}
                        onPurchase={(priceId, price, currencyId) =>
                            handlePurchase('tap_level', priceId, price, currencyId)
                        }
                    />

                    {/* Energy Limit */}
                    <ItemOutlineButton
                        title="Energy Limit"
                        iconUrl={energyLimitIcon}
                        description="Increase the limit of energy storage. +500 energy limit for each level."
                        currentLevel={currentEnergyLevel?.level ?? 0}
                        nextLevel={nextEnergyLevel?.level}
                        hasPremiumTapBot={hasPremiumTapBot}
                        canPurchase={nextEnergyLevel! && nextEnergyLevel.prices.some(
                            ({ price, currencyId }) => canPurchaseItem('energy_limit', price, currencyId)
                        )}
                        purchasing={purchasing}
                        isDisabled={nextEnergyLevel && isItemDisabled('energy_limit', nextEnergyLevel.prices)}
                        isMaxLevel={isMaxLevel('energy_limit')}
                        prices={nextEnergyLevel?.prices ?? []}
                        onPurchase={(priceId, price, currencyId) =>
                            handlePurchase('energy_limit', priceId, price, currencyId)
                        }
                    />

                    {/* Recharge Speed */}
                    <ItemOutlineButton
                        title="Recharge Speed"
                        iconUrl={rechargeSpeedIcon}
                        description="Increase speed of recharge +1 per second."
                        currentLevel={currentRechargeLevel?.level ?? 0}
                        nextLevel={nextRechargeLevel?.level}
                        hasPremiumTapBot={hasPremiumTapBot}
                        canPurchase={nextRechargeLevel! && nextRechargeLevel.prices.some(
                            ({ price, currencyId }) => canPurchaseItem('recharge_speed', price, currencyId)
                        )}
                        purchasing={purchasing}
                        isDisabled={nextRechargeLevel && isItemDisabled('recharge_speed', nextRechargeLevel.prices)}
                        isMaxLevel={isMaxLevel('recharge_speed')}
                        prices={nextRechargeLevel?.prices ?? []}
                        onPurchase={(priceId, price, currencyId) =>
                            handlePurchase('recharge_speed', priceId, price, currencyId)
                        }
                    />

                    {/* TapBots */}
                    <h2 className="text-xl font-regular text-[#1A1A1A]">TapBots</h2>
                    {sortedTapBotLevels.map(tapBotItem => (
                        <ItemOutlineButton
                            key={tapBotItem.level}
                            isTapBot={true}
                            title={tapBotItem.name}
                            iconUrl={tapBotItem.iconUrl}
                            description={tapBotItem.description}
                            currentLevel={currentTapBotLevel?.level ?? 0}
                            nextLevel={tapBotItem.level}
                            hasPremiumTapBot={hasPremiumTapBot}
                            canPurchase={!isTapBotPurchaseDisabled(tapBotItem.level) && tapBotItem.prices.some(
                                ({ price, currencyId }) => canPurchaseItem('tapbot', price, currencyId)
                            )}
                            purchasing={purchasing}
                            isDisabled={isTapBotPurchaseDisabled(tapBotItem.level)}
                            isMaxLevel={false}
                            isHighestTier={tapBotItem.level === highestTierBot.level}
                            duration={tapBotItem.duration}
                            stock={tapBotItem.stock}
                            remains={tapBotItem.remains}
                            specialFeatures={getSpecialFeatures(tapBotItem)}
                            prices={tapBotItem.prices}
                            onPurchase={async (priceId, price, currencyId) => {
                                await handlePurchase('tapbot', priceId, price, currencyId);
                            }}
                        />
                    ))}
                </div >
            </div>
        </div>
    );
};