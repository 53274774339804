import { useLocation } from 'react-router-dom';
import { paths } from '../../config/paths';
import earnIcon from '../../assets/fujiyamatap-icons/bottom-menu-buttons/earn.webp'
import tasksIcon from '../../assets/fujiyamatap-icons/bottom-menu-buttons/tasks.webp'
import gameIcon from '../../assets/fujiyamatap-icons/bottom-menu-buttons/game.webp'
import accountIcon from '../../assets/fujiyamatap-icons/bottom-menu-buttons/account.webp'
import MenuItem from './menu-item';

export const GlobalLayout = ({ children }: { children: React.ReactNode }) => {
    const location = useLocation();

    const isPathActive = (basePath: string) => {
        // Convert the current path to segments
        const currentPathSegments = location.pathname.split('/').filter(Boolean);

        // For root path '/'
        if (basePath === '/' && currentPathSegments[0] === undefined) {
            return true;
        }

        // Check if the first segment matches for each section
        switch (basePath) {
            case paths.home.clicker.path:
                return currentPathSegments[0] === 'clicker' || location.pathname === '/';
            case paths.tasks.cinema.path:
                return currentPathSegments[0] === 'tasks';
            case paths.game.root.path:
                return currentPathSegments[0] === 'game';
            case paths.account.referrals.path:
                return currentPathSegments[0] === 'account';
            case paths.account.stats.path:
                return currentPathSegments[0] === 'stats';
            case paths.account.settings.path:
                return currentPathSegments[0] === 'settings';
            default:
                return false;
        }
    };

    return (
        <div className="flex h-screen flex-col">
            <main className="flex-1">
                {children}
            </main>
            <nav
                className="fixed bottom-0 w-full border-t-4 border-[#F15A24] bg-black"
                style={{
                    paddingBottom: 'calc(env(safe-area-inset-bottom) - 1.5rem)',
                }}
            >
                <ul className="flex items-center justify-center gap-4 overflow-hidden py-4">
                    <MenuItem
                        to={paths.home.clicker.path}
                        icon={earnIcon}
                        label="Earn"
                        isActive={isPathActive(paths.home.clicker.path)}
                    />
                    <MenuItem
                        to={paths.tasks.special.path}
                        icon={tasksIcon}
                        label="Tasks"
                        isActive={isPathActive(paths.tasks.cinema.path)}
                    />
                    <MenuItem
                        to={paths.game.root.path}
                        icon={gameIcon}
                        label="Game"
                        isActive={isPathActive(paths.game.root.path)}
                    />
                    <MenuItem
                        to={paths.account.referrals.path}
                        icon={accountIcon}
                        label="Account"
                        isActive={
                            isPathActive(paths.account.referrals.path) ||
                            isPathActive(paths.account.stats.path) ||
                            isPathActive(paths.account.settings.path)
                        }
                    />
                </ul>
            </nav>
        </div>
    );
};


export default GlobalLayout;