import { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { masterDataAtom, taskProgressAtom, startSpecialTaskAtom, claimSpecialTaskAtom, levelsAtom } from '../../../atoms';
import { SpecialTaskItem } from './specialTaskItem';
import { useNavigate } from 'react-router-dom';
import { backButton } from '@telegram-apps/sdk-react';
import { Play, Check } from 'lucide-react';
import { useToast } from '../../../../components/toast/useToast';
import specialTaskIcon from '../../../../assets/fujiyamatap-icons/task-items/special-task.webp';
import coinIcon from '../../../../assets/fujiyamatap-icons/ui/coin.webp';

export const SpecialTaskRoute = () => {
    const navigate = useNavigate();
    const { showToast } = useToast();
    useEffect(() => {
        backButton.show();
        backButton.onClick(() => {
            navigate(-1);
            backButton.hide();
        });
        return () => {
            backButton.hide();
        };
    }, [navigate]);

    const { specialTaskId } = useParams<{ specialTaskId: string }>();
    const [masterData] = useAtom(masterDataAtom);
    const [taskProgress] = useAtom(taskProgressAtom);
    const [levels] = useAtom(levelsAtom);
    const [, startTask] = useAtom(startSpecialTaskAtom);
    const [, claimTask] = useAtom(claimSpecialTaskAtom);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const task = masterData.specialTasks.find(t => t.taskId === specialTaskId);
    const existingProgress = taskProgress.specialTasks.find(t => t.taskId === specialTaskId);
    const isStarted = Boolean(existingProgress);
    const isTaskCompleted = Boolean(existingProgress?.completedAt);
    const isClaimed = Boolean(existingProgress?.rewardClaimed);

    // 報酬表示の計算ロジック
    const getDisplayReward = () => {
        if (!task) return { level: '', reward: 0 };

        if (existingProgress?.rewardClaimed) {
            // 報酬受け取り済みの場合
            return {
                level: '',
                reward: existingProgress.rewardCoins
            };
        } else {
            // 現在のレベルに応じた報酬を表示
            const currentLevelReward = task.rewards.find(r => r.level === levels.specialTaskLevel);
            return {
                level: `Lv ${levels.specialTaskLevel}`,
                reward: currentLevelReward ? currentLevelReward.reward : task.rewards[0].reward
            };
        }
    };

    const { level: displayLevel, reward: displayReward } = getDisplayReward();

    const handleStart = async () => {
        if (!specialTaskId) return;
        setIsLoading(true);
        try {
            await startTask(specialTaskId);
        } catch (err: unknown) {
            setError((err as Error).message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClaim = async () => {
        if (!specialTaskId) return;
        setIsLoading(true);
        try {
            await claimTask(specialTaskId);
            showToast(
                "Success",
                "success",
                "Reward claimed successfully!"
            );
        } catch (err: unknown) {
            setError((err as Error).message);
            showToast(
                "Error",
                "error",
                "Failed to claim reward!"
            );
        } finally {
            setIsLoading(false);
        }
    };

    if (!task) return (
        <div className="min-h-screen bg-gray-100 p-6">
            <div className="bg-white rounded-lg shadow p-6">
                <p className="text-gray-600">Task not found</p>
            </div>
        </div>
    );

    const sortedItems = [...task.items].sort((a, b) => a.taskItemOrder - b.taskItemOrder);

    return (
        <div className="bg-[#F7F8F8] overflow-scroll h-screen p-8"
            style={{
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 8rem)',
            }}
        >
            <div className="max-w-4xl mx-auto">
                <div className="space-y-2 mb-4">
                    <div className="flex justify-between items-start">
                        <h1 className="text-2xl font-regular text-[#1A1A1A]">{task.title}</h1>
                    </div>

                    {task.description && (
                        <div>
                            <p className="text-gray-600">{task.description}</p>
                        </div>
                    )}

                    <div>
                        <div className="w-full border border-[#B3B3B3] rounded-lg p-4">
                            <div className="flex items-center gap-4">
                                <img src={specialTaskIcon} alt="Special Task" className="w-12 h-12" />
                                <div>
                                    <div className="text-base font-light text-[#1A1A1A]">
                                        Reward {displayLevel && `(${displayLevel})`}
                                    </div>
                                    <div className="flex items-center gap-1 mt-1">
                                        <img src={coinIcon} alt="" className="w-4 h-4" />
                                        <span className="text-sm font-light text-gray-600">
                                            {displayReward.toLocaleString()} coins
                                            {isClaimed && <span className="text-green-600 ml-1">Claimed</span>}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!isStarted && (
                        <button
                            onClick={handleStart}
                            disabled={isLoading}
                            className="w-full py-3 px-4 flex items-center justify-center gap-2 
                                bg-[#DE6437] hover:bg-[#C55832] disabled:bg-[#E89C7D] text-white rounded-lg 
                                font-medium transition-colors duration-200
                                focus:outline-none focus:ring-2 focus:ring-[#DE6437] focus:ring-offset-2"
                        >
                            <Play className="w-5 h-5" />
                            {isLoading ? 'Starting...' : 'Start Task'}
                        </button>
                    )}

                    <div className="space-y-2 mb-4">
                        <h2 className="text-lg font-regular text-[#1A1A1A]">Your Tasks</h2>
                        {!isStarted && (
                            <ul className="list-disc pl-6 mt-2 space-y-2">
                                {sortedItems.map(item => (
                                    <li
                                        key={item.itemId}
                                        className="text-gray-600">
                                        {item.title}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {isStarted && (
                            <div className="space-y-4">
                                {sortedItems.map(item => {
                                    const itemProgress = existingProgress?.taskItems.find(i => i.itemId === item.itemId);
                                    return (
                                        <SpecialTaskItem
                                            key={item.itemId}
                                            taskId={task.taskId}
                                            itemId={item.itemId}
                                            title={item.title}
                                            url={item.url}
                                            type={item.type}
                                            name={item.name}
                                            requireAnswer={item.requireAnswer}
                                            waitDurationSeconds={item.waitDurationSeconds}
                                            isCompleted={Boolean(itemProgress?.completedAt)}
                                        />
                                    );
                                })}

                                {isTaskCompleted && (
                                    isClaimed ? (
                                        <button
                                            disabled
                                            className="w-full py-3 px-4 flex items-center justify-center gap-2 
                                                bg-gray-400 text-white rounded-lg font-medium 
                                                cursor-not-allowed"
                                        >
                                            <Check className="w-5 h-5" />
                                            Reward Claimed
                                        </button>
                                    ) : (
                                        <button
                                            onClick={handleClaim}
                                            disabled={isLoading}
                                            className="w-full py-3 px-4 flex items-center justify-center gap-2 
                                                bg-[#DE6437] hover:bg-[#C55832] disabled:bg-[#E89C7D] text-white rounded-lg 
                                                font-medium transition-colors duration-200
                                                focus:outline-none focus:ring-2 focus:ring-[#DE6437] focus:ring-offset-2"
                                        >
                                            <Check className="w-5 h-5" />
                                            {isLoading ? 'Claiming...' : 'Claim Reward'}
                                        </button>
                                    )
                                )}
                            </div>
                        )}
                    </div>

                    {error && (
                        <div className="bg-[#F7F8F8] border-l-4 border-[#DE6437] p-4">
                            <p className="text-[#DE6437]">{error}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};