import { useEffect, Suspense } from 'react'
import { initData, useSignal } from '@telegram-apps/sdk-react';
import { AppRouter } from './router';
import { useGlobalActions } from './atoms';


function App() {
    const initDataState = useSignal(initData.state);
    const { initializeApp } = useGlobalActions();

    useEffect(() => {
        (async () => {
            if (!initData || !initData.user()) { return; }
            try {
                initializeApp();
            } catch (error) {
                console.error('Failed to initialized', error);
            }
        })()
    }, [initDataState, initializeApp])

    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <AppRouter />
            </Suspense>
        </>
    )
}

export default App
