import { useAtom } from "jotai";
import AccountTabs from "../../../../components/account/accountTabs";
import { metricsAtom } from "../../../../app/atoms";
import coinIcon from "../../../../assets/fujiyamatap-icons/ui/coin.webp"

export const StatsRoute = () => {
    const [metrics] = useAtom(metricsAtom);

    if (!metrics) {
        return (
            <div className="container mx-auto px-4 py-6 bg-[#F7F8F8] h-screen"
                style={{
                    paddingBottom: 'calc(env(safe-area-inset-bottom) + 8rem)',
                }}
            >
                <AccountTabs />
                <div className="mt-4">
                    <div className="mt-12 flex flex-col items-center justify-center text-center">
                        <h2 className="text-xl font-medium text-gray-900 mb-4">Coming Soon</h2>
                        <p className="text-gray-600">
                            Game statistics are currently being collected and will be available soon.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    const stats = [
        {
            title: "Total Balance",
            value: metrics.totalCoinsBalance.toLocaleString(),
            icon: coinIcon,
        },
        {
            title: "Total Players",
            value: metrics.totalPlayers.toLocaleString(),
        },
        {
            title: "Daily Active Users",
            value: metrics.dailyActiveUsers.toLocaleString(),
        },
        {
            title: "Total Touches",
            value: metrics.totalTouches.toLocaleString(),
        }
    ];

    return (
        <div className="container mx-auto px-4 py-6 overflow-scroll h-screen bg-[#F7F8F8]"
            style={{
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 12rem)',
            }}>
            <AccountTabs />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {stats.map((stat) => (
                    <div key={stat.title} className="flex flex-col items-center justify-center">
                        <p className="text-sm font-medium text-gray-600 text-center">{stat.title}</p>
                        <div className="flex items-center justify-center gap-2 mt-2">
                            {stat.icon && (
                                <img src={stat.icon} className="h-4 w-4" />
                            )}
                            <div className="text-2xl font-light text-[#1A1A1A]">{stat.value}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default StatsRoute;