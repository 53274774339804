import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTonConnectModal, useTonWallet, useTonConnectUI } from '@tonconnect/ui-react';
import { OutlineButton } from '../ui/outline-button/OutlineButton';
import { paths } from '../../config/paths';
import connectWalletIcon from '../../assets/fujiyamatap-icons/ui/connect-wallet.webp';
import coinIcon from '../../assets/fujiyamatap-icons/ui/coin.webp';

interface League {
    name: string;
    badgeSmallUrl: string;
    isDarkStage: boolean;
}

interface WalletSectionProps {
    className?: string;
    isDarkStage?: boolean;
}

interface LeagueSectionProps {
    currentLeague: League;
    className?: string;
    isDarkStage?: boolean;
}

interface CoinDisplayProps {
    totalCoins: number;
    className?: string;
    isDarkStage?: boolean;
}

export const WalletSection: React.FC<WalletSectionProps> = ({ className = "", isDarkStage = false }) => {
    const { open } = useTonConnectModal();
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();

    const truncateAddress = (address: string) => {
        if (!address) return '';
        if (address.length <= 8) return address;
        return `${address.slice(0, 8)}...`;
    };

    return (
        <div className={`h-10 ${className}`}>
            {!wallet ? (
                <OutlineButton
                    onClick={open}
                    icon={<img src={connectWalletIcon} alt="Connect Wallet" className="h-5" />}
                    variant={isDarkStage ? "light" : "dark"}
                    showArrow
                >
                    Connect Wallet
                </OutlineButton>
            ) : (
                <OutlineButton
                    icon={<img src={connectWalletIcon} alt="Connect Wallet" className="h-5" />}
                    onClick={() => tonConnectUI.disconnect()}
                    variant={isDarkStage ? "light" : "dark"}
                >
                    {`${wallet.account.chain === '-239' ? '' : 'test:'}${truncateAddress(wallet.account.address)}`}
                </OutlineButton>
            )}
        </div>
    );
};

export const LeagueSection: React.FC<LeagueSectionProps> = ({ currentLeague, className = "", isDarkStage = false }) => {
    const navigate = useNavigate();
    return (
        <div className={`h-10 ${className}`}>
            <OutlineButton
                icon={
                    <img
                        src={currentLeague.badgeSmallUrl}
                        alt={currentLeague.name}
                        className="w-6 h-6"
                    />
                }
                onClick={() => navigate(paths.home.leagues.path)}
                variant={isDarkStage ? "light" : "dark"}
                showArrow
            >
                {currentLeague.name}
            </OutlineButton>
        </div>
    );
};

export const CoinDisplay: React.FC<CoinDisplayProps> = ({ totalCoins, className = "", isDarkStage = false }) => {
    return (
        <div className={`flex justify-center items-center py-4 ${className}`}>
            <div className="flex items-center gap-2">
                <img
                    src={coinIcon}
                    alt="Coin"
                    className="w-6 h-6"
                />
                <span className={`text-lg font-mplus1p font-regular ${isDarkStage ? 'text-white' : 'text-black'}`}>
                    {totalCoins.toLocaleString()}
                </span>
            </div>
        </div>
    );
};

interface GameHeaderProps {
    currentLeague: League;
    totalCoins: number;
    isDarkStage?: boolean;
    className?: string;
}

export const GameHeader: React.FC<GameHeaderProps> = ({
    totalCoins,
    currentLeague,
    isDarkStage = false,
    className = ""
}) => {
    return (
        <div className={className}>
            <div className="flex justify-between font-inter font-light items-center px-4 pt-4">
                <WalletSection isDarkStage={isDarkStage} />
                <LeagueSection currentLeague={currentLeague} isDarkStage={isDarkStage} />
            </div>
            <CoinDisplay totalCoins={totalCoins} isDarkStage={isDarkStage} />
        </div>
    );
};

export default GameHeader;