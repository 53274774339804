import React from 'react';
import { useAtom } from 'jotai';
import { completeCinemaTaskItemAtom } from '../../../atoms';

interface CinemaTaskItemProps {
    taskId: string;
    itemId: string;
    title: string;
    url: string;
    requireAnswer: boolean;
    waitDurationSeconds: number;
    isCompleted: boolean;
}

export const CinemaTaskItem = ({
    taskId,
    itemId,
    title,
    url,
    requireAnswer,
    waitDurationSeconds,
    isCompleted,
}: CinemaTaskItemProps) => {
    const [answer, setAnswer] = React.useState('');
    const [duration, setDuration] = React.useState<number>(0);
    const [hasClickedOpen, setHasClickedOpen] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [isWaiting, setIsWaiting] = React.useState(false);
    const [, completeTaskItem] = useAtom(completeCinemaTaskItemAtom);
    const onComplete = async (itemId: string, answer: string) => {

        if (!taskId) return;
        try {
            await completeTaskItem({
                taskId: taskId,
                itemId,
                answer
            });
        } catch (err: unknown) {
            console.log(err)
            setError('Invalid Answer');
        }
    };

    React.useEffect(() => {
        let timer: NodeJS.Timeout;
        if (duration > 0 && isWaiting) {
            timer = setInterval(() => {
                setDuration(prev => {
                    const newDuration = Math.max(0, prev - 1);
                    if (newDuration === 0) {
                        setError(null);
                    }
                    return newDuration;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [duration, isWaiting]);

    const handleOpen = () => {
        setHasClickedOpen(true);
        setDuration(waitDurationSeconds);
        window.open(url, '_blank');
    };

    const handleActualSubmit = async () => {
        await onComplete(itemId, answer);
        setIsWaiting(false);
    };

    const handleSubmit = () => {
        if (duration > 0) {
            setError("Looks like you didn't watch the video. You need watch the whole video from start to the end in normal speed");
            setIsWaiting(true);
            return;
        }
        handleActualSubmit();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAnswer(e.target.value);
        if (!isWaiting && error !== "Looks like you didn't watch the video. You need watch the whole video from start to the end in normal speed") {
            setError(null);
        }
    };
    return (
        <div className="w-full border border-[#B3B3B3] rounded-lg p-4">
            <div className="flex flex-col space-y-4 w-full">
                <div className="flex justify-between items-center gap-4">
                    <div className="text-base font-light text-[#1A1A1A] flex-1 break-words">
                        {title}
                    </div>
                    <button
                        onClick={handleOpen}
                        disabled={isCompleted}
                        className={`shrink-0 py-2 px-4 rounded-lg text-white font-medium
                    ${isCompleted
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-[#DE6437] hover:bg-[#C55832] disabled:bg-[#E89C7D] transition-colors duration-200'
                            }`}
                    >
                        {isCompleted ? 'Completed' : 'Open'}
                    </button>
                </div>

                {requireAnswer && hasClickedOpen && !isCompleted && (
                    <div className="space-y-3 pt-2">
                        {error && (
                            <p className="text-[#DE6437] text-sm">{error}</p>
                        )}
                        <input
                            type="text"
                            value={answer}
                            onChange={handleInputChange}
                            placeholder="Enter answer"
                            className="w-full px-4 py-3 border border-[#B3B3B3] rounded-lg bg-transparent"
                        />
                        <button
                            onClick={handleSubmit}
                            className={`w-full py-3 px-4 flex items-center justify-center gap-2
                        ${!answer
                                    ? 'bg-[#E89C7D] cursor-not-allowed'
                                    : 'bg-[#DE6437] hover:bg-[#C55832] transition-colors duration-200'
                                }
                        text-white rounded-lg font-medium
                        focus:outline-none focus:ring-2 focus:ring-[#DE6437] focus:ring-offset-2`}
                            disabled={!answer}
                        >
                            Submit Answer
                        </button>
                    </div>
                )}
            </div>
        </div>
    );

};