import React, { useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { X, CheckCircle2, AlertCircle } from 'lucide-react';
import { ToastContext } from './useToast';

// Types
type ToastType = 'success' | 'error';

type ToastProps = {
    id: string;
    title: string;
    description?: string;
    type: ToastType;
    duration?: number;
};

export type ToastContextType = {
    showToast: (title: string, type: ToastType, description?: string, duration?: number) => void;
};

// Toast Component
const Toast = ({ title, description, type, onClose }: {
    title: string;
    description?: string;
    type: ToastType;
    onClose: () => void;
}) => {
    const bgColor = type === 'success' ? 'bg-green-950' : 'bg-red-950';
    const iconColor = type === 'success' ? 'text-green-400' : 'text-red-400';

    return (
        <div
            className={`flex items-start gap-x-4 ${bgColor} text-white px-4 py-3 
            rounded-lg shadow-lg transform transition-all duration-300 ease-out
            animate-slide-up min-w-[320px] max-w-md`}
        >
            <div className={`${iconColor} flex-shrink-0 self-center`}>
                {type === 'success' ? (
                    <CheckCircle2 className="w-5 h-5" />
                ) : (
                    <AlertCircle className="w-5 h-5" />
                )}
            </div>
            <div className="flex-1">
                <p className="text-sm font-medium">{title}</p>
                {description && (
                    <p className="mt-1 text-sm text-gray-300">{description}</p>
                )}
            </div>
            <button
                onClick={onClose}
                className="text-gray-400 hover:text-white transition-colors duration-200 flex-shrink-0 -mt-1 -mr-1"
            >
                <X className="w-4 h-4" />
            </button>
        </div>
    );
};

// Toast Container
const ToastContainer = ({ toasts, removeToast }: {
    toasts: ToastProps[];
    removeToast: (id: string) => void;
}) => {
    return createPortal(
        <div className="fixed bottom-0 left-0 right-0 z-50 flex flex-col items-center gap-2 p-4 pb-[calc(env(safe-area-inset-bottom)+1rem)]">
            {toasts.map((toast) => (
                <Toast
                    key={toast.id}
                    title={toast.title}
                    description={toast.description}
                    type={toast.type}
                    onClose={() => removeToast(toast.id)}
                />
            ))}
        </div>,
        document.body
    );
};

// Provider Component
export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
    const [toasts, setToasts] = useState<ToastProps[]>([]);

    const removeToast = useCallback((id: string) => {
        setToasts(prev => prev.filter(toast => toast.id !== id));
    }, []);

    const showToast = useCallback((
        title: string,
        type: ToastType,
        description?: string,
        duration = 3000
    ) => {
        const id = Math.random().toString(36).substr(2, 9);
        setToasts(prev => [...prev, {
            id,
            title,
            type,
            description,
            duration,
        }]);

        setTimeout(() => {
            removeToast(id);
        }, duration);
    }, [removeToast]);

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            <ToastContainer toasts={toasts} removeToast={removeToast} />
        </ToastContext.Provider>
    );
};