
import { Link } from 'react-router-dom';

interface MenuItemProps {
    to: string;
    icon: string;
    label: string;
    isActive?: boolean;
}

const MenuItem = ({ to, icon, label, isActive = false }: MenuItemProps) => (
    <li>
        <Link
            to={to}
            className={`
          flex flex-col items-center justify-center
          w-16 h-16
          border rounded-md
          font-inter font-light
          transition-colors duration-200
          ${isActive
                    ? 'border-[#F15A24] text-white'
                    : 'border-[#4D4D4D] text-white hover:border-[#4D4D4D]'
                }
        `}
        >
            <img
                src={icon}
                alt={label}
                className="w-8 mb-0.5" // 16x16px
            />
            <span className="text-[10px]">{label}</span>
        </Link>
    </li>
);

export default MenuItem;