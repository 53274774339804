import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { paths } from '../../config/paths';
import { GameHeader } from '../GameHeader/GameHeader';
import { useAtom } from 'jotai';
import { currentLeagueAtom, coinsAtom, localClickerStateAtom } from '../../app/atoms';

const TASK_TABS = [
    { name: 'Special', path: paths.tasks.special.path },
    { name: 'Cinema', path: paths.tasks.cinema.path },
    { name: 'Leagues', path: paths.tasks.leagues.path },
    { name: 'Ref', path: paths.tasks.referrals.path }
] as const;

export const TaskTabs = () => {
    const location = useLocation();
    const [currentLeague] = useAtom(currentLeagueAtom);
    const [serverCoins] = useAtom(coinsAtom);
    const [localState] = useAtom(localClickerStateAtom);

    const displayValues = useMemo(() => {
        if (!localState.isInitialized) {
            return {
                totalCoins: serverCoins.calculation.totalCoins,
            };
        }
        return {
            totalCoins: localState.localCoinCalculation.totalCoins,
        };
    }, [
        localState.isInitialized,
        localState.localCoinCalculation.totalCoins,
        serverCoins.calculation.totalCoins,
    ]);

    return (
        <nav className="mb-4">
            <GameHeader
                currentLeague={currentLeague}
                totalCoins={displayValues.totalCoins}
            />
            <div className="flex justify-center items-center">
                <div className="grid grid-cols-4 gap-2">
                    {TASK_TABS.map(tab => {
                        const isActive = location.pathname === tab.path;
                        return (
                            <Link
                                key={tab.name}
                                to={tab.path}
                                className={`
                                    py-2
                                    w-20
                                    font-light 
                                    text-sm
                                    rounded-lg
                                    transition-all
                                    duration-200
                                    text-center
                                    ${isActive
                                        ? 'border border-[#F9825D] text-[#F9825D]'
                                        : 'border border-transparent text-gray-500 hover:text-[#F9825D]'
                                    }
                                `}
                            >
                                {tab.name}
                            </Link>
                        );
                    })}
                </div>
            </div>
        </nav>
    );
};

export default TaskTabs;