import { Link, useLocation } from 'react-router-dom';
import { paths } from '../../config/paths';

const ACCOUNT_TABS = [
    { name: 'Referrals', path: paths.account.referrals.path },
    { name: 'Stats', path: paths.account.stats.path },
    { name: 'Settings', path: paths.account.settings.path }
] as const;

const AccountTabs = () => {
    const location = useLocation();

    return (
        <nav className="mb-4">
            <div className="flex justify-center items-center">
                <div className="grid grid-cols-3 gap-2">
                    {ACCOUNT_TABS.map(tab => {
                        const isActive = location.pathname === tab.path;
                        return (
                            <Link
                                key={tab.name}
                                to={tab.path}
                                className={`
                                    py-2
                                    w-20
                                    font-light 
                                    text-sm
                                    rounded-lg
                                    transition-all
                                    duration-200
                                    text-center
                                    ${isActive
                                        ? 'border border-[#F9825D] text-[#F9825D]'
                                        : 'border border-transparent text-gray-500 hover:text-[#F9825D]'
                                    }
                                `}
                            >
                                {tab.name}
                            </Link>
                        );
                    })}
                </div>
            </div>
        </nav>
    );
};

export default AccountTabs;