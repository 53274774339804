import AccountTabs from '../../../../components/account/accountTabs';
import LegalLinks from '../../../../components/account/settings/ExternalLinkButton';

export const SettingsRoute = () => {
    return (
        <div className="container mx-auto px-4 py-6 overflow-scroll h-screen bg-[#F7F8F8]"
            style={{
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 8rem)',
            }}
        >
            <AccountTabs />
            <div className="mt-6">
                <div className="max-w-2xl">
                    <LegalLinks />
                </div>
            </div>
        </div>
    );
};

export default SettingsRoute;