import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { X } from 'lucide-react';
import coinIcon from '../../../assets/fujiyamatap-icons/ui/coin.webp';
import tonIcon from '../../../assets/fujiyamatap-icons/ui/ton_symbol.svg';
import { PaymentItemType } from '../../../../functions/api/v1/payment/ton/transactions/type';

interface PendingPayment {
    itemType: PaymentItemType;
    transactionId: string;
    price: number;
    createdAt: number;
    currencyId: string;
    tonDestinationWalletAddress: string;
    itemId?: string;
    priceId: string;
}

interface PaymentRecoveryModalProps {
    isOpen: boolean;
    pendingPayments: PendingPayment[];
    onProcess: (transactionId: string) => Promise<boolean>;
    onCancel: () => void;
    onSuccess?: () => void;
    processingPaymentId?: string | null;
}

const PaymentRecoveryModal = ({
    isOpen,
    pendingPayments,
    onProcess,
    onCancel,
    onSuccess,
    processingPaymentId
}: PaymentRecoveryModalProps) => {
    const [processingPayments, setProcessingPayments] = useState<Record<string, boolean>>({});
    const [elapsedTimes, setElapsedTimes] = useState<Record<string, number>>({});
    const [lastClickTimes, setLastClickTimes] = useState<Record<string, number>>({});

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            const interval = setInterval(() => {
                setElapsedTimes(() => {
                    const updated: Record<string, number> = {};
                    Object.keys(lastClickTimes).forEach(id => {
                        if (lastClickTimes[id]) {
                            updated[id] = Math.floor((Date.now() - lastClickTimes[id]) / 1000);
                        }
                    });
                    return updated;
                });
            }, 1000);
            return () => {
                clearInterval(interval);
                document.body.style.overflow = 'unset';
            };
        }
    }, [isOpen, lastClickTimes]);

    const formatElapsedTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleProcess = async (transactionId: string) => {
        setProcessingPayments(prev => ({ ...prev, [transactionId]: true }));
        setLastClickTimes(prev => ({ ...prev, [transactionId]: Date.now() }));
        try {
            const success = await onProcess(transactionId);
            if (success && onSuccess) {
                onSuccess();
            }
        } catch (error) {
            console.error('Payment processing error:', error);
        } finally {
            setProcessingPayments(prev => ({ ...prev, [transactionId]: false }));
        }
    };

    const getCurrencyIcon = (currencyId: string) => {
        if (currencyId === 'ton') return tonIcon;
        if (currencyId === 'coin') return coinIcon;
        return coinIcon;
    };

    const isProcessing = (transactionId: string) => {
        return processingPaymentId === transactionId || processingPayments[transactionId];
    };

    const buttonContent = (transactionId: string) => {
        if (isProcessing(transactionId)) {
            return (
                <span className="flex items-center justify-center">
                    <svg className="mr-2 h-4 w-4 animate-spin" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    Verifying...
                    {lastClickTimes[transactionId] &&
                        ` (${formatElapsedTime(elapsedTimes[transactionId] || 0)})`
                    }
                </span>
            );
        }
        return 'Check Payment';
    };

    if (!isOpen) return null;

    const modalContent = (
        <div
            className="fixed inset-0 z-[1000] flex items-center justify-center bg-black/50 px-4"
            onClick={onCancel}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
        >
            <div
                className="w-full max-w-lg transform overflow-hidden rounded-lg bg-white shadow-xl transition-all"
                onClick={e => e.stopPropagation()}
            >
                <div className="px-6 py-5">
                    <button
                        onClick={onCancel}
                        className="absolute right-4 top-4 rounded-full p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                    >
                        <X className="h-6 w-6" />
                    </button>
                    <h2
                        id="modal-title"
                        className="text-xl text-gray-900"
                    >
                        Resume Pending Payments
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                        We detected the following unfinished payments
                    </p>
                </div>

                <div className="max-h-[60vh] space-y-4 overflow-y-auto px-6">
                    {pendingPayments.map((payment) => (
                        <div key={payment.transactionId} className="rounded-lg bg-white">
                            <div className="mb-3 flex flex-col items-center">
                                <img
                                    src={getCurrencyIcon(payment.currencyId)}
                                    alt={payment.currencyId}
                                    className="h-12 w-12 mb-2"
                                />
                                <span className="text-lg text-gray-900">
                                    {payment.price} {payment.currencyId.toUpperCase()}
                                </span>
                                <span className="text-sm text-gray-500">
                                    {new Date(payment.createdAt).toLocaleString()}
                                </span>
                            </div>

                            <button
                                onClick={() => handleProcess(payment.transactionId)}
                                disabled={isProcessing(payment.transactionId)}
                                className="w-full rounded-lg bg-[#DE6437] px-4 py-4 text-sm text-white transition-all duration-200 hover:bg-[#C55832] focus:outline-none disabled:cursor-not-allowed disabled:opacity-60"
                            >
                                {buttonContent(payment.transactionId)}
                            </button>
                        </div>
                    ))}
                </div>

                <div className="px-6 pb-6 pt-4">
                    <button
                        onClick={onCancel}
                        disabled={Object.values(processingPayments).some(Boolean)}
                        className="w-full rounded-lg border border-gray-200 bg-white px-4 py-2 text-sm text-gray-700 transition-all duration-200 hover:bg-gray-50 focus:outline-none disabled:cursor-not-allowed disabled:opacity-60"
                    >
                        Cancel All
                    </button>
                </div>
            </div>
        </div>
    );

    return createPortal(
        modalContent,
        document.getElementById('modal-root') || document.body
    );
};

export default PaymentRecoveryModal;