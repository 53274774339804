import { useState } from 'react';
import ConfirmationModal from '../../../ConfirmationModal/ConfirmationModal';

export type ItemFilledButtonProps = {
    icon?: string;
    title: string;
    description: string;
    remainingUses: number;
    maxUses: number;
    isDisabled: boolean;
    onUse: () => Promise<void>;
    additionalText?: string;
    iconClassName?: string;
    confirmText?: string;
    onSuccess?: () => void;
    price?: {
        amount: number;
        currency: string;
    };
    navigateAfterPurchase?: boolean;
};

const ItemFilledButton = ({
    icon,
    title,
    description,
    remainingUses,
    maxUses,
    isDisabled,
    onUse,
    iconClassName = 'w-12 h-12',
    confirmText = 'Use Item',
    onSuccess,
    price,
    navigateAfterPurchase = false
}: ItemFilledButtonProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        if (!isDisabled) {
            setIsModalOpen(true);
        }
    };

    const handleConfirm = async () => {
        try {
            setIsLoading(true);
            await onUse();
            onSuccess?.();
        } catch (error) {
            console.error('Error using item:', error);
        } finally {
            setIsLoading(false);
            if (!navigateAfterPurchase) {
                setIsModalOpen(false);
            }
        }
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <button
                onClick={handleClick}
                disabled={isDisabled}
                className={`text-left font-mplus1p bg-[#E6E6E6] p-4 rounded-lg min-w-[48%]
                    ${!isDisabled ? 'hover:bg-gray-200 active:bg-gray-300' : 'opacity-70 cursor-not-allowed'}`}
            >
                <div className="flex flex-row items-center gap-2">
                    {icon && (
                        <div className={`flex-shrink-0 ${iconClassName}`}>
                            <img
                                src={icon}
                                alt={title}
                                className="w-full h-full object-contain"
                            />
                        </div>
                    )}
                    <div className="flex flex-col justify-center min-w-0">
                        <h3 className="text-sm font-mplus1p leading-snug text-[#1A1A1A]">{title}</h3>
                        <p className="text-sm font-mplus1p text-gray-600">{`${remainingUses}/${maxUses}`}</p>
                    </div>
                </div>
            </button>

            <ConfirmationModal
                isOpen={isModalOpen}
                onClose={handleClose}
                onConfirm={handleConfirm}
                icon={icon}
                title={title}
                description={description}
                confirmText={confirmText}
                isLoading={isLoading}
                price={price}
            />
        </>
    );
};

export default ItemFilledButton;