import { X } from 'lucide-react';
import Modal from '../Modal';
import { useEffect, useState } from 'react';
import sakuraTapBotIcon from '../../../../assets/fujiyamatap-icons/boost/sakura-bot.webp'
import ninjaTapBotIcon from '../../../../assets/fujiyamatap-icons/boost/ninja-bot.webp'
import fujiyamaTapBotIcon from '../../../../assets/fujiyamatap-icons/boost/fujiyama-bot.webp'
import coinIcon from '../../../../assets/fujiyamatap-icons/ui/coin.webp'

interface TapBotEarningsModalProps {
    isOpen: boolean;
    onClose: () => void;
    earnings: number;
    botLevel: number;
}

const TapBotEarningsModal = ({ isOpen, onClose, earnings, botLevel }: TapBotEarningsModalProps) => {
    const [isClosing, setIsClosing] = useState(false);

    const botImages = [
        { level: 1, url: sakuraTapBotIcon },
        { level: 2, url: ninjaTapBotIcon },
        { level: 3, url: fujiyamaTapBotIcon },
    ];

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            onClose();
        }, 300);
    };

    useEffect(() => {
        if (!isOpen) {
            setIsClosing(false);
        }
    }, [isOpen]);

    const currentBotImage = botImages.find(img => img.level === botLevel)?.url || botImages[0].url;

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            position="bottom"
            className={`h-[60vh] rounded-t-2xl transform transition-transform duration-300 ease-out
                ${isOpen && !isClosing ? 'translate-y-0' : 'translate-y-full'}`}
        >
            <div className="h-full flex flex-col relative">
                {/* Close button */}
                <button
                    onClick={handleClose}
                    className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                >
                    <X className="w-6 h-6 text-gray-500" />
                </button>

                <div className="flex-1 flex items-center px-6">
                    <div className="w-full">
                        {/* Bot Level Image */}
                        <div className="flex justify-center mb-4">
                            <img
                                src={currentBotImage}
                                alt={`Level ${botLevel} Bot`}
                                className="w-12 h-12"
                            />
                        </div>

                        {/* Header */}
                        <div className="text-center mb-6">
                            <h1 className="text-2xl font-regular text-[#1A1A1A] mb-2">
                                Tap Bot
                            </h1>
                            <p className="text-gray-600">
                                While you were asleep, Tap Bot earned some coins for you!
                            </p>
                        </div>

                        {/* Earnings display */}
                        <div className="flex flex-col items-center gap-4 py-4">
                            <div className="flex items-center gap-1">
                                <img
                                    src={coinIcon}
                                    alt="Coin"
                                    className="w-6 h-6"
                                />
                                <span className="text-2xl font-light text-[#1A1A1A]">
                                    {earnings.toLocaleString()}
                                </span>
                            </div>
                        </div>

                        {/* Footer */}
                        <div className="mt-6 flex justify-center">
                            <button
                                onClick={handleClose}
                                className="w-full py-3 px-4 flex items-center justify-center gap-2 
                                    bg-[#DE6437] hover:bg-[#C55832] disabled:bg-[#E89C7D] text-white rounded-lg 
                                    font-medium transition-colors duration-200
                                    focus:outline-none focus:ring-2 focus:ring-[#DE6437] focus:ring-offset-2"
                            >
                                Get it!
                            </button>
                        </div>
                    </div>
                </div>
                {/* safe-area-inset-bottom margin */}
                <div style={{ paddingBottom: 'env(safe-area-inset-bottom)' }} />
            </div>
        </Modal>
    );
};

export default TapBotEarningsModal;