export interface ClickRequest {
    clicks: number;
    clientRequestedTime: number;
}

export interface UpdateDailyCheckinReward {
    earnedCoins: {
        dailyCheckinRewards: number
    }
}

export interface ClickResponse {
    player: {
        clicker: {
            clicks: number;
            energy: number;
            freeBoostDosageLimit: number;
            freeRechargeLimit: number;
            lastSyncTime: number;
        };
        coins: {
            clickEarnedCoins: number;
        };
        levels: {
            tapLevel: number;
            tapBotLevel?: number;
            energyLimitLevel: number;
            rechargeSpeedLevel: number;
            cinemaTaskLevel: number;
            specialTaskLevel: number;
            leagueTaskLevel: number;
            referralTaskLevel: number;
        };
        boost?: {
            effectiveUntil: number;
            effectiveAt: number;
            multiplier: number;
        };
        tapBotState: {
            isActive: boolean;
            level?: number;
            botClicks: number;
            botEarnedCoins: number;
            tapBotEarnStart?: number;
            tapBotEarnUntil?: number;
        };
    };
    summary: {
        earnedCoins: number;
        currentEnergy: number;
        currentEarnedCoins: number;
        lastSyncTime: number;
    };
}

// エラーの型は変更なし
export class GameError extends Error {
    constructor(
        message: string,
        public code: GameErrorCode,
        public status: number = 400
    ) {
        super(message);
        this.name = 'GameError';
    }
}

export enum GameErrorCode {
    // ユーザー関連
    UserNotFound = 'USER_NOT_FOUND',
    GameStateNotFound = 'GAME_STATE_NOT_FOUND',
    LevelNotFound = 'LEVEL_NOT_FOUND',

    // バリデーション関連
    InvalidRequest = 'INVALID_REQUEST',
    InvalidClickCount = 'INVALID_CLICK_COUNT',
    InvalidTimestamp = 'INVALID_TIMESTAMP',
    ExcessiveClickRate = 'EXCESSIVE_CLICK_RATE',
    InsufficientEnergy = 'INSUFFICIENT_ENERGY',
    ExcessEnergy = 'EXCESS_ENERGY',

    // その他
    DatabaseError = 'DATABASE_ERROR',
    UnknownError = 'UNKNOWN_ERROR',
}