import React from 'react';
import { useAtom } from 'jotai';
import { referralsAtom } from '../../../app/atoms';

interface ReferralUserProps {
    anonymizedUserId: string;
    country: string;
    registeredAt: number;
}

const ReferralUser: React.FC<ReferralUserProps> = ({ anonymizedUserId, country, registeredAt }) => {
    const registeredDate = new Date(registeredAt).toLocaleDateString();

    return (
        <tr className="hover:bg-gray-50">
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-[#1A1A1A]">{anonymizedUserId}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-[#1A1A1A]">{country}</div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-[#1A1A1A]">{registeredDate}</div>
            </td>
        </tr>
    );
};

const ReferralList: React.FC = () => {
    const [referrals] = useAtom(referralsAtom);
    const [currentPage, setCurrentPage] = React.useState(1);
    const itemsPerPage = 20;

    const totalPages = Math.ceil(referrals.children.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentReferrals = referrals.children.slice(startIndex, startIndex + itemsPerPage);

    return (
        <div className="w-full">
            <div className="overflow-x-auto">
                <table className="w-full">
                    <thead className="border-b-2 border-[#DE6437]">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DE6437] uppercase tracking-wider">
                                ID
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DE6437] uppercase tracking-wider">
                                Country
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DE6437] uppercase tracking-wider">
                                Date
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {currentReferrals.map((referral) => (
                            <ReferralUser
                                key={referral.anonymizedUserId}
                                anonymizedUserId={referral.anonymizedUserId}
                                country={referral.country}
                                registeredAt={referral.registeredAt}
                            />
                        ))}
                    </tbody>
                </table>
            </div>

            {totalPages > 1 && (
                <div className="mt-4 flex justify-center">
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        <button
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-[#DE6437] hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                        >
                            Previous
                        </button>
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                            <button
                                key={page}
                                onClick={() => setCurrentPage(page)}
                                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium ${currentPage === page
                                    ? 'z-10 bg-[#DE6437] border-[#DE6437] text-white'
                                    : 'bg-white text-[#DE6437] hover:bg-gray-50'
                                    }`}
                            >
                                {page}
                            </button>
                        ))}
                        <button
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-[#DE6437] hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
                        >
                            Next
                        </button>
                    </nav>
                </div>
            )}
        </div>
    );
};

export default ReferralList;