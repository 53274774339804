import { useState, useCallback } from 'react';
import { useAtom } from 'jotai';
import { useTonWallet } from '@tonconnect/ui-react';
import { TonClient, fromNano, Address } from '@ton/ton';
import { tonTransactionAtom, tonVerificationAtom, accessTokenAtom } from '../../../app/atoms';
import { PaymentItemType, PostTonTransactionResponse } from '../../../../functions/api/v1/payment/ton/transactions/type';

interface TonPaymentOptions {
    pollingInterval?: number;
}

export interface TonTransaction {
    transactionId: string;
    tonDestinationWalletAddress: string;
    price: number;
    createdAt: number;
    itemType: PaymentItemType;
}

interface VerificationResult {
    verified: boolean;
    transactionId: string;
    verifiedAt: number;
    tonTransactionHash?: string;
    tonFromWalletAddress?: string;
}

export const useTonPayment = (options: TonPaymentOptions = {}) => {
    const wallet = useTonWallet();
    const [tonTransaction, setTonTransaction] = useAtom(tonTransactionAtom);
    const [tonVerification, setTonVerification] = useAtom(tonVerificationAtom);
    const [accessToken] = useAtom(accessTokenAtom);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const pollingInterval = options.pollingInterval || 3000;

    const createTransaction = useCallback(async (params: {
        itemType: string;
        priceId: string;
    }) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch('/api/v1/payment/ton/transactions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    item: {
                        itemType: params.itemType,
                        priceId: params.priceId
                    }
                })
            });

            if (!response.ok) {
                throw new Error('Failed to create TON transaction');
            }

            const data: PostTonTransactionResponse = await response.json();
            const transaction: TonTransaction = {
                ...data.data.transaction,
                itemType: params.itemType as PaymentItemType
            };
            setTonTransaction(transaction);
            return transaction;
        } catch (error) {
            setError(error instanceof Error ? error : new Error('Unknown error'));
            throw error;
        } finally {
            setLoading(false);
        }
    }, [setTonTransaction, accessToken]);

    const verifyTransactionOnChain = useCallback(async (
        destinationAddress: string,
        expectedAmount: number,
        transactionId: string
    ): Promise<{ verified: boolean; hash?: string; fromAddress?: string }> => {
        if (!wallet) {
            throw new Error('Wallet not connected');
        }
        try {
            const endpoint = wallet.account.chain === '-239' ? 'https://toncenter.com/api/v2/jsonRPC' : 'https://testnet.toncenter.com/api/v2/jsonRPC';
            const client = new TonClient({ endpoint });
            const address = Address.parse(destinationAddress);
            const transactions = await client.getTransactions(address, { limit: 10 });

            // Convert transactionId to SHA-256 hash
            const encoder = new TextEncoder();
            const data = encoder.encode(transactionId);
            const hashBuffer = await crypto.subtle.digest('SHA-256', data);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const expectedHashedId = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

            for (const tx of transactions) {
                const inMsg = tx.inMessage;
                if (inMsg?.info.type === 'internal') {
                    const body = inMsg.body.beginParse();
                    if (body.remainingBits < 32) continue;

                    const op = body.loadUint(32);
                    if (op !== 0) continue;

                    const comment = body.loadStringTail();
                    if (comment !== expectedHashedId) continue;

                    const value = Number(fromNano(inMsg.info.value.coins));
                    if (value === expectedAmount) {
                        return {
                            verified: true,
                            hash: tx.hash().toString('hex'),
                            fromAddress: inMsg.info.src.toString()
                        };
                    }
                }
            }
            return { verified: false };
        } catch (error) {
            console.error('Chain verification error:', error);
            return { verified: false };
        }
    }, [wallet]);

    const verifyTransactionWithAPI = useCallback(async (
        transactionId: string,
        itemType: PaymentItemType
    ): Promise<VerificationResult> => {
        const response = await fetch('/api/v1/payment/ton/verify', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                transactionId,
                itemType // itemTypeをリクエストに追加
            })
        });

        if (!response.ok) {
            throw new Error('Failed to verify transaction with API');
        }

        const data = await response.json();
        setTonVerification(data.data);
        return data.data;
    }, [setTonVerification]);

    const waitForTransactionComplete = useCallback(async (transaction: TonTransaction): Promise<VerificationResult> => {
        return new Promise((resolve, reject) => {
            let attempts = 0;
            const maxAttempts = 60 * 5; // 5分間試行

            const checkTransaction = async () => {
                try {
                    console.log('Checking transaction...');
                    console.log('tonDestinationWalletAddress: ', transaction.tonDestinationWalletAddress)
                    console.log('price: ', transaction.price)
                    const chainVerification = await verifyTransactionOnChain(
                        transaction.tonDestinationWalletAddress,
                        transaction.price,
                        transaction.transactionId
                    );
                    console.log('chainVerification: ', chainVerification)

                    if (chainVerification.verified) {
                        // チェーン上で確認できたら、API側でも検証
                        const apiVerification = await verifyTransactionWithAPI(transaction.transactionId, transaction.itemType);
                        if (apiVerification.verified) {
                            resolve(apiVerification);
                            return;
                        }
                    }

                    attempts++;
                    if (attempts >= maxAttempts) {
                        reject(new Error('Transaction verification timeout'));
                        return;
                    }

                    setTimeout(checkTransaction, pollingInterval);
                } catch (error) {
                    reject(error);
                }
            };

            checkTransaction();
        });
    }, [verifyTransactionOnChain, verifyTransactionWithAPI, pollingInterval]);

    const cleanup = useCallback(() => {
        setTonTransaction(null);
        setTonVerification(null);
        setError(null);
    }, [setTonTransaction, setTonVerification]);

    return {
        tonTransaction,
        tonVerification,
        loading,
        error,
        createTransaction,
        waitForTransactionComplete,
        cleanup
    };
};