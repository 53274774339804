import { useAtom } from 'jotai';
import { referralsAtom } from '../../../../app/atoms';
import AccountTabs from "../../../../components/account/accountTabs";
import ReferralList from "../../../../components/account/referrals/referralsList";
import { useToast } from '../../../../components/toast/useToast';
import { Copy } from 'lucide-react';

export const ReferralsRoute = () => {
    const [referrals] = useAtom(referralsAtom);
    const { showToast } = useToast();

    const handleCopyLink = async () => {
        if (referrals.friendBotId && referrals.referralCode) {
            const referralLink = `https://t.me/${referrals.friendBotId}?start=${referrals.referralCode}`;

            try {
                await navigator.clipboard.writeText(referralLink);
                showToast(
                    "Success",
                    "success",
                    "Referral link copied to clipboard!"
                );
            } catch (err) {
                console.error(err);
                showToast(
                    "Error",
                    "error",
                    "Failed to copy referral link to clipboard!"
                );
            }
        }
    };

    return (
        <div className="container mx-auto px-4 py-6 overflow-scroll h-screen bg-[#F7F8F8]"
            style={{
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 8rem)',
            }}
        >
            <AccountTabs />
            <div className="mt-6 space-y-6">
                <button
                    onClick={handleCopyLink}
                    className="w-full py-3 px-4 flex items-center justify-center gap-2 
                        bg-[#DE6437] hover:bg-[#C55832] text-white rounded-lg 
                        font-medium transition-colors duration-200
                        focus:outline-none focus:ring-2 focus:ring-[#DE6437] focus:ring-offset-2"
                >
                    <Copy className="w-5 h-5" />
                    Copy Referral Link
                </button>
                <ReferralList />
            </div>
        </div>
    );
};

export default ReferralsRoute;