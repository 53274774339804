import { useEffect, useState } from 'react';

export interface CoinPopupProps {
    id: number;
    amount: number;
    x: number;
    y: number;
    onComplete: () => void;
}

// コインポップアップのコンポーネント
export const CoinPopup = ({ amount, x, y, onComplete }: CoinPopupProps) => {
    const [style, setStyle] = useState({
        transform: 'translate(-50%, -50%)',
        opacity: 1,
        top: y,
        left: x,
        transition: 'none'
    });

    useEffect(() => {
        // 開始直後に変更をアニメーション化
        requestAnimationFrame(() => {
            setStyle({
                transform: 'translate(-50%, -100%)',
                opacity: 0,
                top: y - 80, // 80px上に移動
                left: x,
                transition: 'all 1s ease-out'
            });
        });

        // アニメーション終了後にクリーンアップ
        const timer = setTimeout(onComplete, 1000);
        return () => clearTimeout(timer);
    }, [x, y, onComplete]);

    return (
        <div
            className="fixed pointer-events-none text-white font-bold text-lg"
            style={{
                ...style,
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)'
            }}
        >
            +{amount.toLocaleString()}
        </div>
    );
};

export default CoinPopup;