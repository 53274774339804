import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { AppRoot } from '@telegram-apps/telegram-ui';
import { init } from './init';
import '@telegram-apps/telegram-ui/dist/styles.css';
import './index.css'
import './mockEnv.ts';
import App from './app/index'

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://4cb100b2d2b85c6d4bbac8c9b2838ff4@o4508393473966080.ingest.us.sentry.io/4508435219546112",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const hostname = window.location.hostname;
const isLocal = hostname.includes('local') || hostname === '127.0.0.1';
init(isLocal);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AppRoot>
      <App />
    </AppRoot>
  </StrictMode>,
)