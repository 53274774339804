import { useAtom } from 'jotai';
import { useState } from 'react';
import { masterDataAtom, taskProgressAtom, referralsAtom, claimReferralTaskAtom, levelsAtom } from '../../../atoms';
import { TaskTabs } from '../../../../components/tasks/tasktabs';
import SingleTaskCard from '../../../../components/tasks/SingleTaskCard';
import referralTaskIcon from '../../../../assets/fujiyamatap-icons/task-items/referral-task.webp'

export const ReferralsTaskRoute = () => {
    const [masterData] = useAtom(masterDataAtom);
    const [taskProgress, setTaskProgress] = useAtom(taskProgressAtom);
    const [referrals] = useAtom(referralsAtom);
    const [levels] = useAtom(levelsAtom);
    const [, claimReferralTask] = useAtom(claimReferralTaskAtom);
    const [claimedTaskIds, setClaimedTaskIds] = useState<Set<string>>(new Set());

    const isTaskRewardClaimed = (taskId: string) => {
        const progress = taskProgress.referralTasks.find(p => p.taskId === taskId);
        const isClaimedLocally = claimedTaskIds.has(taskId);
        return progress?.rewardClaimed || isClaimedLocally;
    };

    const getTaskRewards = (task: typeof masterData.referralTasks[0], progress: typeof taskProgress.referralTasks[0] | undefined) => {
        if (progress?.rewardClaimed) {
            // 報酬受け取り済みの場合は、その報酬額のみを含む配列を返す
            return [{
                level: 0,
                reward: progress.rewardCoins
            }];
        }
        // 未受領の場合は、通常の報酬配列を返す
        return task.rewards;
    };

    const handleClaim = async (taskId: string) => {
        try {
            await claimReferralTask(taskId);
            setClaimedTaskIds(prev => new Set(prev).add(taskId));
            setTaskProgress(prev => ({
                ...prev,
                referralTasks: prev.referralTasks.map(task =>
                    task.taskId === taskId
                        ? {
                            ...task,
                            rewardClaimed: true,
                            completedAt: Date.now(),
                        }
                        : task
                )
            }));
        } catch (error) {
            console.error('Failed to claim task:', error);
        }
    };

    return (
        <div
            className="max-w-7xl mx-auto overflow-scroll h-screen bg-[#F7F8F8]"
            style={{
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 8rem)',
            }}
        >
            <TaskTabs />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
                {masterData.referralTasks.map((task) => {
                    const currentReferralCount = referrals.children.length;
                    const isTaskCompleted = currentReferralCount >= task.requiredCount;
                    const rewardClaimed = isTaskRewardClaimed(task.taskId);
                    const progress = taskProgress.referralTasks.find(p => p.taskId === task.taskId);

                    return (
                        <SingleTaskCard
                            key={task.taskId}
                            task={{
                                taskId: task.taskId,
                                title: task.title,
                                reward: getTaskRewards(task, progress),
                                requiredCoins: task.requiredCount
                            }}
                            currentCoins={currentReferralCount}
                            status={`${currentReferralCount}/${task.requiredCount}`}
                            canClaim={isTaskCompleted && !rewardClaimed}
                            completedAt={progress?.completedAt}
                            rewardClaimed={rewardClaimed}
                            taskIconUrl={referralTaskIcon}
                            currentTaskLevel={rewardClaimed ? 0 : levels.referralTaskLevel}
                            onClaim={handleClaim}
                        />
                    );
                })}
            </div>
        </div>
    );
};