import React, { forwardRef } from 'react';

interface FullscreenClickerProps {
    onInteraction: (e: React.MouseEvent | React.TouchEvent) => void;
    isBoostActive: boolean;
    boostTimeRemaining: number | null;
    isAnimating: boolean;
    disabled: boolean;
    // Visual layers
    bgColor?: string;
    bgEffectLayer?: React.ReactNode;
    boostEffectLayer?: React.ReactNode;
    stageLayer: React.ReactNode;
    clickerSymbolLayer: React.ReactNode;
    clickerSymbolOverlayLayer?: React.ReactNode;
}

export const FullscreenClicker = forwardRef<HTMLButtonElement, FullscreenClickerProps>(({
    onInteraction,
    isBoostActive,
    boostTimeRemaining,
    isAnimating,
    disabled,
    bgColor = "#000000",
    bgEffectLayer,
    boostEffectLayer,
    stageLayer,
    clickerSymbolLayer,
    clickerSymbolOverlayLayer
}, ref) => {
    const [, setUpdateTrigger] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setUpdateTrigger(prev => (prev + 1) % 1000000); // オーバーフロー防止のため1000000でリセット
        }, 500); // 1秒間に2回の更新

        return () => clearInterval(intervalId);
    }, []);

    // Calculate opacity for fade out effect when boost is ending
    const boostOpacity = React.useMemo(() => {
        if (!isBoostActive || !boostTimeRemaining) return 0;
        if (boostTimeRemaining > 5000) return 1;
        return boostTimeRemaining / 5000;
    }, [isBoostActive, boostTimeRemaining]);

    return (
        <div className="relative min-h-screen">
            {/* Main Clicker Area */}
            <div className="">
                <button
                    ref={ref}
                    onTouchStart={onInteraction}
                    onMouseDown={onInteraction}
                    disabled={disabled}
                    className={`
                        w-screen h-screen relative
                        ${!disabled ? '' : 'opacity-50 cursor-not-allowed'}
                    `}
                    style={{ backgroundColor: bgColor }}
                >
                    {/* Background Layer */}
                    {bgEffectLayer && (
                        <div className="absolute inset-0 h-full overflow-hidden">
                            {bgEffectLayer}
                        </div>
                    )}

                    {/* Stage Layer */}
                    <div className="w-screen absolute inset-x-0 top-24">
                        {stageLayer}
                    </div>

                    {/* Boost Effect Layer - Moved after Stage Layer */}
                    {isBoostActive && boostEffectLayer && (
                        <div
                            className="w-screen absolute inset-x-0 top-24 transition-opacity duration-300"
                            style={{ opacity: boostOpacity }}
                        >
                            {boostEffectLayer}
                        </div>
                    )}

                    {/* Clicker Symbol - Animated */}
                    <div className={`
                        w-screen absolute inset-x-0 top-60 scale-75 -translate-y-4
                        ${isAnimating ? 'scale-[0.78] -translate-y-5' : 'scale-100'}
                        transition-all duration-100 ease-in-out
                    `}>
                        {clickerSymbolLayer}
                    </div>

                    {/* Optional Overlay */}
                    {clickerSymbolOverlayLayer && (
                        <div className="absolute inset-x-0 bottom-0">
                            {clickerSymbolOverlayLayer}
                        </div>
                    )}
                </button>
            </div>
        </div>
    );
});

export default FullscreenClicker;