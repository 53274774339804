import { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { toNano, beginCell } from '@ton/ton';
import { useTonPayment } from '../../../payment/ton/usetonpayment';
import { usePaymentRecovery } from '../../../payment/ton/usePaymentRecovery';
import {
    dailyCheckinStateAtom,
    performDailyCheckinAtom,
    globalActionsAtom
} from '../../../../app/atoms';
import { TonTransaction } from '../../../payment/ton/usetonpayment';

export const useDailyCheckin = (isInitLoading: boolean) => {
    const [showDailyCheckinModal, setShowDailyCheckinModal] = useState(false);
    const [hasShownModal, setHasShownModal] = useState(false);
    const [isPerformingCheckin, setIsPerformingCheckin] = useState(false);
    const [isVerifyingTransaction, setIsVerifyingTransaction] = useState(false);
    const { addPendingPayment, removePendingPayment, pendingPayments } = usePaymentRecovery();

    const [dailyCheckinState] = useAtom(dailyCheckinStateAtom);
    const [, performCheckin] = useAtom(performDailyCheckinAtom);
    const [, dispatch] = useAtom(globalActionsAtom);
    const [tonConnectUI] = useTonConnectUI();

    const {
        createTransaction,
        waitForTransactionComplete,
        cleanup: cleanupTonPayment
    } = useTonPayment();

    useEffect(() => {
        if (!isInitLoading && !hasShownModal &&
            !dailyCheckinState.completed &&
            dailyCheckinState.offers.length > 0) {
            setShowDailyCheckinModal(true);
            setHasShownModal(true);
        }
    }, [isInitLoading, hasShownModal, dailyCheckinState]);

    const handleCloseModal = () => {
        setShowDailyCheckinModal(false);
    };

    const handleSelectPackage = async (
        itemId: string,
        priceId: string,
        currencyId: string = 'coins',
        existingTransactionId?: string
    ) => {
        if (isPerformingCheckin) return;

        if (currencyId === 'coin') {
            setIsPerformingCheckin(true);
            try {
                const data = await performCheckin({
                    itemId: itemId
                });
                await dispatch({
                    type: 'UPDATE_COINS',
                    payload: {
                        earnedCoins: {
                            dailyCheckinRewards:
                                data.data.dailyCheckin.completed.rewardToday.rewardCoin
                        }
                    }
                });
                handleCloseModal();
            } catch (error) {
                console.error('Failed to perform daily check-in:', error);
            } finally {
                setIsPerformingCheckin(false);
            }
            return;
        }

        try {
            setIsPerformingCheckin(true);

            let transaction: TonTransaction;
            if (existingTransactionId) {
                // リカバリーケース: 既存のトランザクション情報を使用
                const existingPayment = pendingPayments.find(p => p.transactionId === existingTransactionId);
                if (!existingPayment) {
                    throw new Error('Pending payment not found');
                }

                transaction = {
                    transactionId: existingPayment.transactionId,
                    tonDestinationWalletAddress: existingPayment.tonDestinationWalletAddress,
                    price: existingPayment.price,
                    createdAt: existingPayment.createdAt,
                    itemType: existingPayment.itemType
                };
            } else {
                // 新規ケース: 新しいトランザクションを作成
                transaction = await createTransaction({
                    itemType: 'daily_checkin',
                    priceId: priceId
                });

                // トランザクションIDのハッシュ化
                const encoder = new TextEncoder();
                const transactionData = encoder.encode(transaction.transactionId);
                const hashBuffer = await crypto.subtle.digest('SHA-256', transactionData);
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                const hashedId = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

                // 新規トランザクションの場合のみウォレット承認を要求
                await tonConnectUI.sendTransaction({
                    validUntil: Date.now() + 5 * 60 * 1000,
                    messages: [
                        {
                            address: transaction.tonDestinationWalletAddress,
                            amount: toNano(transaction.price).toString(),
                            payload: beginCell()
                                .storeUint(0, 32)
                                .storeStringTail(hashedId)
                                .endCell()
                                .toBoc()
                                .toString('base64')
                        },
                    ],
                });
            }

            // トランザクション検証中の状態を設定
            setIsVerifyingTransaction(true);

            addPendingPayment({
                itemType: 'daily_checkin',
                itemId: itemId,
                priceId: priceId,
                price: transaction.price,
                transactionId: transaction.transactionId,
                tonDestinationWalletAddress: transaction.tonDestinationWalletAddress,
                createdAt: transaction.createdAt,
                currencyId: 'ton',
            });

            // トランザクション検証
            const verification = await waitForTransactionComplete(transaction);

            if (!verification.verified) {
                throw new Error('Transaction verification failed');
            }

            // チェックイン実行
            console.log('Performing daily check-in..., itemId:', itemId, 'transactionId:', transaction.transactionId);
            const data = await performCheckin({
                itemId: itemId,
                transactionId: transaction.transactionId
            });

            if (verification.verified) {
                cleanupTonPayment();
                removePendingPayment(transaction.transactionId);

                await dispatch({
                    type: 'UPDATE_COINS',
                    payload: {
                        earnedCoins: {
                            dailyCheckinRewards:
                                data.data.dailyCheckin.completed.rewardToday.rewardCoin
                        }
                    }
                });

                // 成功時に必ずモーダルを閉じる
                handleCloseModal();
            }

        } catch (error) {
            console.error('Failed to perform daily check-in:', error);
        } finally {
            setIsPerformingCheckin(false);
            setIsVerifyingTransaction(false);
        }
    };

    return {
        showDailyCheckinModal,
        handleCloseModal,
        handleSelectPackage,
        packages: dailyCheckinState.offers,
        isPerformingCheckin,
        isVerifyingTransaction, // 新しく追加した状態を公開
    };
};

export default useDailyCheckin;