import { Outlet } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { paths } from '../../config/paths';
import GlobalLayout from '../../components/layout/layout';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import manifestUrl from '../../assets/tonconnect-manifest.json?url';
import { ToastProvider } from '../../components/toast/toast';


export const AppRoot = () => {
    return (
        <GlobalLayout>
            <TonConnectUIProvider manifestUrl={manifestUrl}>
                <ToastProvider>
                    <Outlet />
                </ToastProvider>
            </TonConnectUIProvider>
        </GlobalLayout>
    );
};


export const AppRootErrorBoundary = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // ルートパス（/）以外でエラーが発生した場合はリダイレクト
        if (location.pathname !== '/') {
            navigate(paths.home.path);
        }
    }, [navigate, location]);

    // ルートパスの場合は専用のエラーメッセージを表示
    if (location.pathname === '/') {
        return <p>something went wrong in clicker!</p>;
    }

    // その他のパスの場合はリダイレクト中のメッセージを表示
    return <div>Something went wrong! Redirecting to home page...</div>;
};