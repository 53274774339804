import boostEffect from '../../../assets/fujiyamatap-icons/stage/boost.webp'

export const GameRoute = () => {
    return (
        <div className="h-screen flex items-center justify-center bg-[#F7F8F8] overflow-hidden"
            style={{
                paddingBottom: 'calc(env(safe-area-inset-bottom) + 8rem)',
            }}
        >
            <div className="relative">
                <img
                    src={boostEffect}
                    alt="boost effect"
                    className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 z-2 scale-[3]"
                />
                <h1 className="font-mplus1p font-thin text-4xl text-[#1A1A1A]">Coming Soon</h1>
            </div>
        </div>
    )
}

export default GameRoute