import React from 'react';
import { ChevronRight } from 'lucide-react';

interface OutlineButtonProps {
    icon?: React.ReactNode;
    children: React.ReactNode;
    onClick?: () => void;
    variant?: "light" | "dark";
    className?: string;
    disabled?: boolean;
    showArrow?: boolean;
}

export const OutlineButton = ({
    icon,
    children,
    onClick,
    variant = 'light',
    className = '',
    disabled = false,
    showArrow = false
}: OutlineButtonProps) => {
    const textColorClass = variant === "light" ? "text-white" : "text-black";
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`
                flex items-center gap-2 px-4 h-10 rounded-lg border border-[#B3B3B3] 
                bg-transparent transition-colors duration-200 w-fit
                ${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:border-[#F9825D]'}
                ${className}
            `}
        >
            {icon && <span className={`flex-shrink-0`}>{icon}</span>}
            <span
                className={`
                    text-xs font-inter font-light text-[#1A1A1A]  ${textColorClass}
                `}
            >
                {children}
            </span>
            {showArrow && <ChevronRight className={`w-4 h-4 ml-1 ${textColorClass}`} />}
        </button>
    );
};

export default OutlineButton;